import React, { useEffect, useState } from "react";

import { getLocationIcon, getLocationNameLS, RenderFooter, RenderHeader } from "./FbsLanding";

import { default as QRCode } from 'qrcode.react';
import { axiosInstance as axios } from "../utils/axios";
import { getStorage, setStorage } from "../utils/storage";
import animConfetti from "../assets/confetti-subtle_lf20_lmggs7gq.json";
import Lottie from "lottie-react";
import { generateId } from "../utils/helpers";
import { PROFILE_IMG_FALLBACK, URL_BASE_FBS } from "../config.common";

export function FbsLandingQR(props) {
    const [configurable, setConfigurable] = useState({
        fetchTemplateEvery: 5, // minutes
        showStaffPhoto: true,
        showLHSAnimation: true,
        fallbackImage: PROFILE_IMG_FALLBACK,
        greeting: "Namaskara",
        meta1: "This area is cleaned & maintained by me today.",
        meta2: "How am I doing?",
        qrHeading: "Please share your experience",
        qrSubHeading: "Your feedback will help us improve",
        showLocTxtBelowQR: true,
    })

    const [fbsMeta, setFbsMeta] = useState(getStorage('fbsMeta'));
    const [viewMode, setViewMode] = useState("horizontal"); // horizontal/vertical
    const [elementWidth, setElementWidth] = useState(320);
    const [template, setTemplate] = useState(getStorage('template'));
    const [staffProfileType, setStaffProfileType] = useState('profile'); // profile/card

    /* QR CODE CONTENT for displaying QR */
    const prepend = URL_BASE_FBS + "/m/feedback?hash="; // URL_BASE would be the base URL of the feedback system (fbs./feedback.)
    const qrCodePayload = {
        loi: fbsMeta?.location?.id, // Location ID
        lon: fbsMeta?.location?.location, // Location Name
        id: Math.ceil(Math.random() * 100), // Random ID
        hashKey: generateId(6), geek: "@thinkdj", // Random Hash Key
        oi: fbsMeta?.location?.org_id ?? fbsMeta?.org_id, // Org ID
    }
    const qrCodeText = btoa(unescape(encodeURIComponent(JSON.stringify(qrCodePayload))));

    const getTheTemplate = async () => {
        const apiUrl = "/fbs/public/get-the-template"; // unified code
        const response = await axios.post(apiUrl, { location_id: fbsMeta?.location?.id, org_id: fbsMeta?.org_id });
        if (response?.data?.id === 0) {
            console.error("No associated templates found for " + fbsMeta?.location?.location + ". Using default");
        }
        else if (response && response.data) {
            setTemplate(response.data);
        }

        let orgNameString = ((response?.data?.org?.name || '') + " " + (response?.data?.org?.shortname || '')).toLowerCase();
        console.log("orgNameString", orgNameString);
        /* Higher order of precedence downwards; DO NOT USE `else if` */
        if (orgNameString.includes('bial')) {
            document.getElementById("app-container-global").classList.add('theme-custom-bial'); console.log("Setting theme: bial");
            setConfigurable({ ...configurable, showLocTxtBelowQR: false })
            setElementWidth(() => window.innerWidth > 1600 ? 400 : 300)
        }
        // if(orgNameString.includes('encalm')) { document.getElementById("app-container-global").classList.add('theme-custom-encalm-light'); console.log("Setting theme: Encalm.light"); }
        // if(orgNameString.includes('mial')) { document.getElementById("app-container-global").classList.add('theme-custom-violet','theme-custom-mial'); console.log("Setting theme: MIAL"); }
        if (orgNameString.includes('vesta')) { // Dev Testing (Theme testing)
            // document.getElementById("app-container-global").classList.add('theme-custom-bial');
        }
    }
    /* Init, first call */
    useEffect(() => {
        getTheTemplate().then();
    }, [fbsMeta]);

    /* Get template updates every x minutes */
    const interval = React.useRef();
    useEffect(() => {
        interval.current = setInterval(() => { getTheTemplate().then(); }, (configurable.fetchTemplateEvery) * 1000 * 60);
        return () => { clearInterval(interval.current) }
    }, []);

    const logoToUse = props.logo ?? getStorage('u4')?.hotel?.logo_img ?? null;

    return (
        <>
            <div id="header">
                <div id="header-left">
                    {/* LHS: Empty*/}
                </div>
                <div id="header-center">
                </div>
                <div id="header-right">
                    {/* RHS */}
                    {logoToUse ?
                        <img src={logoToUse} alt="" />
                        : <></>}
                </div>
            </div>

            <div style={{ textAlign: 'center' }}>

                {viewMode === "horizontal" ?
                    <div className="fbs-qr-container" style={{ display: 'grid', gridTemplateColumns: "repeat(2,1fr)", minWidth: 1200, gridGap: 50, alignItems: 'center' }}>

                        {/* LHS */}
                        <div style={{ paddingTop: 24, paddingRight: 16, borderRight: '2px solid rgba(255,255,255,0.32)', position: 'relative' }}>
                            {configurable.showStaffPhoto ?
                                <img src={template?.location?.assoc_staff?.avatar ?? configurable.fallbackImage} alt="Photo" className="fbs-loc-staff-profile-img" />
                                : null}
                            <h3 style={{ textAlign: 'center', lineHeight: 1.5, fontSize: '1.5rem' }}>
                                <span>
                                    {configurable.greeting ? <strong className="fbs-greeting">{configurable.greeting}</strong> : null}
                                    {(template?.location?.assoc_staff?.fname ?? template?.location?.assoc_staff?.name) ? <span className="fbs-greeting">, I'm {template?.location?.assoc_staff?.fname ?? template?.location?.assoc_staff?.name}</span> : null}
                                </span>
                                {configurable.meta1 ? <small style={{ opacity: 0.50, display: 'block', paddingTop: 18, paddingBottom: 2 }}>{configurable.meta1}</small> : null}
                                {configurable.meta2 ? <small style={{ opacity: 0.50, display: 'block' }}>{configurable.meta2}</small> : null}
                            </h3>
                            {configurable.showLHSAnimation ? <Lottie animationData={animConfetti} loop={true} className={``} style={{ width: elementWidth * 1.975, position: 'absolute', zIndex: 0, top: -150, left: -20 }} /> : null}
                        </div>

                        {/* RHS */}
                        <div>

                            <h5 style={{ padding: 0, marginTop: 20, fontSize: '1.5rem', lineHeight: 1.5 }}>
                                {configurable.qrHeading ? <span className="fbs-qr-heading">{configurable.qrHeading}</span> : null}
                                {configurable.qrSubHeading ? <small style={{ opacity: 0.50, display: 'block' }}>{configurable.qrSubHeading}</small> : null}
                            </h5>

                            <div style={{ padding: 18, background: "#fff", marginBottom: 10, width: elementWidth, height: elementWidth, boxSizing: "content-box", textAlign: "center", margin: '0 auto' }} className="fbs-block-prominent">
                                <a href={prepend + qrCodeText} target="_dj">
                                    <QRCode
                                        value={prepend + qrCodeText}
                                        size={elementWidth}
                                        bgColor={"#FFF"}
                                        fgColor={"#000"}
                                        level={"L"}
                                        includeMargin={false}
                                        renderAs={"svg"}
                                    ></QRCode>
                                </a>
                            </div>
                            {configurable.showLocTxtBelowQR ? <b className="d-b p-t-b-25 col-dark opa-050 f-s-1-2r">{getLocationIcon()} {getLocationNameLS()}</b> : null}
                        </div>

                    </div>
                    : <></>
                }


                {viewMode === "vertical" ? <div>
                    {(staffProfileType === "profile") ? <div style={{ position: 'relative' }}>
                        <img src="https://randomuser.me/api/portraits/men/47.jpg" alt="" style={{ borderRadius: '50%', width: 200, objectFit: 'cover', border: '8px solid rgba(0,0,0,.125)', position: 'absolute', zIndex: 1000, left: '50%', marginLeft: "-20%", top: 65 }} />
                        <Lottie animationData={animConfetti} loop={true} className={``} style={{ width: 490 }} />
                        <h3 style={{ textAlign: 'center', lineHeight: 1.3 }}>
                            Hi, I'm Rahul
                            <small style={{ opacity: 0.60, display: 'block' }}>How am I doing?</small>
                        </h3>
                    </div>
                        : <></>
                    }
                    {(staffProfileType === "card") ?
                        <div style={{ display: 'grid', gridTemplateColumns: '75px auto', gridGap: 15, padding: '10px 5px', alignItems: 'center', justifyContent: 'center' }} className="fbs-block-prominent">
                            <div>
                                <img src="https://randomuser.me/api/portraits/women/37.jpg" alt="" style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', border: '4px solid rgba(0,0,0,.075)' }} />
                            </div>
                            <h4 style={{ textAlign: 'left', lineHeight: 1.3 }}>
                                Hi, I'm Reshmi
                                <small style={{ opacity: 0.60, display: 'block' }}>How am I doing?</small>
                            </h4>
                        </div>
                        : <></>
                    }
                    <div>
                        <h5 style={{ padding: 0, marginTop: 20 }}>Please share your feedback</h5>

                        <div style={{ padding: 18, background: "#fff", marginBottom: 10, width: elementWidth, height: elementWidth, boxSizing: "content-box", textAlign: "center", margin: '0 auto' }} className="fbs-block-prominent">
                            <QRCode
                                value={prepend + qrCodeText}
                                size={elementWidth}
                                bgColor={"#FFFFFF"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                                renderAs={"svg"}
                            ></QRCode>
                        </div>
                        <b className="d-b p-t-b-10 col-dark opa-050 f-s-1-2r">{getLocationIcon()} {getLocationNameLS()}</b>
                    </div>
                </div>
                    : <></>
                }



            </div>
            <RenderFooter />
        </>
    )
};

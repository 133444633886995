import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDate } from "../useDate";
import { useForm } from "react-hook-form";
import { axiosInstance as axios } from "../utils/axios";
import { decodeUrlHashString, getUrlParameterByName } from "../utils/helpers";
import {
    ActionSheet, ActionSheetButton,
    BackButton,
    Button,
    Card,
    Checkbox,
    Page,
    ProgressCircular,
    Radio,
    Select,
    Toolbar
} from "react-onsenui";
import { ErrorMessage } from "@hookform/error-message";
import { makeDate_Std, makeTime } from "../utils/date-helper";
import Lottie from "lottie-react";
import animInfoBlue from "../assets/info-blue.json";
import { paths } from "../App";

/* Webcam for taking photos */
import Webcam from "react-webcam";
import { Icon } from '@iconify/react';

// Standard storage functions for general use
import {
    deleteItem,
    getItem,
    getStorage,
    removeItem,
    removeItemsContaining,
    setItem,
    setStorage,
} from "../utils/storage";

// Checklist-specific storage functions with IndexedDB support
import {
    getChecklistData,
    setChecklistData,
    deleteChecklistData,
    getImageData,
    setImageData,
    deleteImageData,
    migrateToIndexedDB,
    cleanupIndexedDB
} from "../utils/storage";

export const ChecklistSheet = (props) => {
    const [authUser, setAuthUser] = useState(getStorage('u4'));
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isIndexedDBReady, setIsIndexedDBReady] = useState(false);

    /* Navigation */
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => {
        // navigate(-1); // This wont work if user is coming directly to CL details page from QR code; No history;
        navigate(paths.checklist.dash, { replace: true });
    }

    /* Unsaved changes? @todo: fix this code, it's not working */
    useEffect(() => {
        const handleBackNavigation = () => {
            if (hasUnsavedChanges && !window.confirm("You have unsaved changes. Are you sure you want to leave this page?")) {
                navigate(-1, { replace: true });
            }
        };
        window.addEventListener("popstate", handleBackNavigation);
        return () => {
            window.removeEventListener("popstate", handleBackNavigation);
        };
    }, [hasUnsavedChanges, location, navigate]);

    /* Passed data */
    const passedData = location?.state?.data || {};

    /* State */
    const [imageArray, setImageArray] = useState([]);
    const [enableWebCam, setEnableWebCam] = useState(false);
    const [currentWebCamIndex, setCurrentWebCamIndex] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const dateRT = useDate();

    const [loc, setLoc] = useState(null); // location
    const [bpId, setBpId] = useState(null); //clBPId
    const [clBP, setClBP] = useState(null); // BP
    const [locId, setLocId] = useState(null); // locID
    const [orgId, setOrgId] = useState(null);
    const [clInstance, setClInstance] = useState(null); // clInstance
    const [decodedData, setDecodedData] = useState(null);
    const [clInstanceNotFound, setClInstanceNotFound] = useState(false);
    const [lastWorkedInstance, setLastWorkedInstance] = useState(false);
    const [clComments, setClComments] = useState([]);
    const [openSectionIndex, setOpenSectionIndex] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [validationError, setValidationError] = useState('');

    const [forceRefresh, setForceRefresh] = useState(0); // Used to force UI refreshes

    /* LocalStorage data into a variable */
    const [localStorageInstanceData, setLocalStorageInstanceData] = useState(null);

    /* Form */
    const { register, handleSubmit, watch, reset, setValue, getValues, formState: { errors }, trigger } = useForm();

    const getWhatCLToWorkOn = async () => {
        const roleId = parseInt(getStorage('u4R')) || null;
        return axios.post("/checklist/fetch-workable-instance", {
            clBP_id: bpId,
            loc_id: locId,
            role_id: roleId,
            org_id: orgId,
        });
    }

    const getApiCLBlueprintData = async (clBP_id = bpId) => {
        if (!clBP_id) return;
        return axios.get("/checklist/blueprint/get", { params: { type: "blueprint", id: clBP_id, } });
    }

    /* Get CL Comments * /
    const getApiComments = async () => {
        if(!authUser) return;
        const results = axios.get("/checklist/comments/list", { params: {
                org_id: authUser?.hotel_id,
                type: "comment",
        }})
        return results;
    }
    useEffect(()=>{
        getApiComments().then(r=>setClComments(r?.data?.data)) // paginated // disabled -- not used, so
    },[]);
    /* */

    const localDecode = passedData?.localDecode ?? false;
    const localInstanceId = passedData?.clInstance_id ?? 0;

    /* Reset the form AFTER we get clInstance and clBp set */
    useEffect(() => {
        if (localDecode && localInstanceId && isIndexedDBReady) {
            console.log(`Loading locally saved checklist with ID ${localInstanceId} from IndexedDB`);

            // Get the checklist data from IndexedDB
            getChecklistData(localInstanceId).then(data => {
                if (data) {
                    // Load the form data
                    if (data.formData) {
                        reset(data.formData);
                    }

                    // Set checklist instance and blueprint
                    if (data.clInstance) {
                        setClInstance(data.clInstance);
                    }

                    if (data.clBp) {
                        setClBP(data.clBp);
                    }

                    // Set image array if available
                    if (data.imageArray && Array.isArray(data.imageArray)) {
                        setImageArray(data.imageArray);
                    }

                    console.log('Successfully loaded checklist data from IndexedDB');
                } else {
                    console.warn(`No data found in IndexedDB for checklist ${localInstanceId}`);

                    // Fall back to legacy localStorage format only for compatibility
                    const lsObject = getItem(`cache_instance_${localInstanceId}`);
                    if (lsObject?.formData) {
                        console.log('Found legacy localStorage data, will migrate to IndexedDB');
                        reset(lsObject.formData);
                    }
                }
            }).catch(error => {
                console.error(`Error loading checklist ${localInstanceId} from IndexedDB:`, error);
            });
        }
    }, [localDecode, localInstanceId, isIndexedDBReady, reset]);

    /* Initialize form values and closing options after data is loaded */
    useEffect(() => {
        // Only proceed if we have loaded a checklist from storage
        if (localDecode && localInstanceId && localStorageInstanceData?.formData) {
            const formData = localStorageInstanceData.formData;

            // Reset form with the saved data
            reset(formData);

            // Initialize selected options state with saved values
            const savedOptions = {};

            // Extract all closing_option values from formData
            Object.keys(formData).forEach(key => {
                if (key.startsWith('items_with_meta_') && formData[key]?.meta?.closing_option) {
                    const formNameClosingOpt = `${key}[meta][closing_option]`;
                    const optionValue = formData[key].meta.closing_option;

                    // Update selected options state
                    savedOptions[formNameClosingOpt] = optionValue;

                    // Ensure the form values are set
                    setValue(formNameClosingOpt, optionValue);

                    console.log(`Setting saved option: ${formNameClosingOpt}=${optionValue}`);
                }
            });

            // Update selected options state
            setSelectedOptions(savedOptions);

            // Open all sections that have data
            if (clBP?.sections) {
                const sectionsWithData = [];
                clBP.sections.forEach((section, index) => {
                    // Check if this section has any items with data
                    const hasData = clBP.items?.some(item => {
                        const itemKey = `items_with_meta_${item.id}`;
                        return formData[itemKey]?.meta?.closing_option;
                    });

                    if (hasData) {
                        sectionsWithData.push(index);
                    }
                });

                // Open all sections that have data
                if (sectionsWithData.length > 0) {
                    setOpenSectionIndex(sectionsWithData);
                }
            }
        }
    }, [localStorageInstanceData, clBP, localDecode, localInstanceId, reset, setValue]);

    // Keep sections with errors expanded
    useEffect(() => {
        if (errors && Object.keys(errors).length > 0 && clBP?.sections) {
            // Find sections with errors
            const sectionsWithErrors = clBP.sections
                .map((section, index) => sectionHasErrors(section.id) ? index : -1)
                .filter(index => index !== -1);
            
            // Keep sections with errors expanded
            if (sectionsWithErrors.length > 0) {
                setOpenSectionIndex(prevOpen => {
                    const newOpenSections = [...prevOpen];
                    sectionsWithErrors.forEach(index => {
                        if (!newOpenSections.includes(index)) {
                            newOpenSections.push(index);
                        }
                    });
                    return newOpenSections;
                });
            }
        }
    }, [errors, clBP]);

    // Add a new effect to initialize IndexedDB and migrate data once on component mount
    useEffect(() => {
        const initializeStorage = async () => {
            try {
                // Try to migrate any existing localStorage data to IndexedDB
                const migrationResult = await migrateToIndexedDB();
                console.log(`IndexedDB migration ${migrationResult ? 'successful' : 'not needed'}`);

                // Clean up any invalid entries in IndexedDB
                try {
                    const removedCount = await cleanupIndexedDB();
                    if (removedCount > 0) {
                        console.log(`Cleaned up ${removedCount} invalid checklist entries`);
                    }
                } catch (cleanupError) {
                    console.warn("IndexedDB cleanup failed:", cleanupError);
                }

                setIsIndexedDBReady(true);
            } catch (error) {
                console.error('Failed to initialize IndexedDB:', error);
                // Still mark as ready so the app can proceed even if IndexedDB init fails
                setIsIndexedDBReady(true);
            }
        };

        initializeStorage();
    }, []);

    /* VERY, VERY IMPORTANT: */
    /* Optimization is pending... Images are in an [] with key as index ... So item 100 will have images [,,,,,100] */
    /* Need to make this {id:x, img:''} format and use find(), filter() etc. */
    /* Applies to items_with_meta, imagesArray */
    /* ====== UPDATE 20 Apr 2023 - RECODED WITH ImageArray.FIND() AND ITEMS_WITH_META_xyz => FIXED? */

    /* Initialize */
    useEffect(() => {
        /* Process from Local ? */
        if (localDecode && localInstanceId) {
            setLoadingMessage("Retrieving saved Form");

            // Use the updated getChecklistData function which prioritizes IndexedDB
            getChecklistData(localInstanceId).then(lsObject => {
                if (lsObject) {
                    let clBp_id_local = lsObject?.clInstance?.clBP_id ?? 0;
                    setBpId(clBp_id_local);
                    setLocalStorageInstanceData(lsObject);
                    setClInstance(lsObject?.clInstance ?? {});
                    setImageArray(lsObject?.imageArray ?? []);
                    setLocId(lsObject?.clInstance?.clLoc_id);
                    setOrgId(lsObject?.clInstance?.org_id);
                    setTimeout(() => { setLoadingMessage('Loading images') }, 800);
                    setTimeout(() => { setLoadingMessage('') }, 1200);
                    /* Blueprint not saved locally? Refetch */
                    if (lsObject?.clBp && lsObject?.clBp?.id) {
                        setClBP(lsObject?.clBp ?? {});
                    } else {
                        console.log("Refetching clBP")
                        getApiCLBlueprintData(clBp_id_local).then(r => {
                            setClBP(r.data); // also triggers reset of formdata
                        });
                        return;
                    }
                } else {
                    setTimeout(() => { setLoadingMessage('Error loading local data. Going back.') }, 50);
                    setTimeout(() => { navigate(paths.checklist.dash) }, 500);
                }
            }).catch(err => {
                console.error("Error loading checklist data:", err);
                setTimeout(() => { setLoadingMessage('Error loading local data. Going back.') }, 50);
                setTimeout(() => { navigate(paths.checklist.dash) }, 500);
            });
            return;
        }

        /* Decode URL PARAMS */
        const hash = getUrlParameterByName('hash', passedData.decodedText);
        const decoded = decodeUrlHashString(hash);
        console.log('passedData', passedData);
        console.log('params', hash);
        console.log('decoded', decoded);
        console.log('decoded bpi', decoded.bpi, 'bpn', decoded.bpn);
        console.log('decoded loi', decoded.loi, 'lon', decoded.lon);
        if (!decoded.bpi || !decoded.loi || !hash) {
            alert("Invalid QR Code");
            navigate(paths.checklist.dash)
        }
        /* Set Decoded */
        setDecodedData(decoded);
        /* Set BpId */
        setBpId(decoded?.bpi || props?.bpi);
        /* Set LocId */
        setLocId(decoded?.loi || props?.loi);
        /* Set Org_id -- important to take blueprint's org */
        setOrgId(decoded?.oi || props?.oi || authUser?.hotel_id);
    }, []);

    /* Fetch Location Details */
    useEffect(() => {
        if (!locId || !clBP) return;
        const locFromBP = clBP?.locations?.find(l => l.id == locId);
        if (!locFromBP) {
            // Fetch using Axios
        } else {
            setLoc(locFromBP);
        }
    }, [locId, clBP]);

    /* Find an Instance to work on */
    useEffect(() => {
        // Fetch workable instance
        const fetchData = async () => {
            // Skip if we're using a locally saved checklist
            if (localDecode) {
                console.log("Using locally saved checklist, skipping fetch-workable-instance");
                return;
            }

            // Make sure we have the necessary data to make the API call
            if (!bpId) {
                console.log("No blueprint ID available yet, skipping fetch-workable-instance");
                return;
            }

            if (!decodedData) {
                console.log("No decoded QR data available yet, skipping fetch-workable-instance");
                return;
            }

            // Make sure IndexedDB is ready
            if (!isIndexedDBReady) {
                console.log("IndexedDB not ready yet, skipping fetch-workable-instance");
                return;
            }

            /* ORG CHECK: Check if user has permission to work on CL [#60] */
            if (!authUser?.hotel_id) {
                console.log("No authenticated user, skipping fetch-workable-instance");
                return;
            }

            const usersOrgIds = authUser.hotels?.map(h => h.id) ?? [authUser?.hotel_id ?? 0];
            if (usersOrgIds.indexOf(decodedData?.oi) === -1) {
                alert("You do not have permission to work on this checklist.");
                navigate(paths.checklist.dash);
                return;
            }

            console.log(`Fetching workable instance for blueprint ${bpId}`);

            /* Get API Data */
            try {
                const r = await getWhatCLToWorkOn();
                console.log("fetch-workable-instance response:", r.data);

                if ((r.data.hasOwnProperty('workable-instance') && !r.data["workable-instance"]) || r.data === 'INSTANCE-NOT-FOUND') {
                    setLastWorkedInstance(r.data['last-worked-instance'] ?? 'not-found');
                    setClInstanceNotFound(true);
                }
                else if (r.data && r.data?.id) {
                    setClInstance(r.data);
                    setClInstanceNotFound(false);

                    // Get blueprint master details (on success of instance)
                    try {
                        const bpResponse = await getApiCLBlueprintData();
                        setClBP(bpResponse.data);
                    } catch (bpError) {
                        console.error("Failed to fetch blueprint data:", bpError);
                    }

                    // Store checklist instance in IndexedDB immediately when we get it
                    const instanceId = String(r.data.id);
                    const checklistData = {
                        clInstance: r.data,
                        timestamp: new Date().toISOString()
                    };

                    try {
                        await setChecklistData(instanceId, checklistData);
                        console.log(`Successfully saved initial checklist instance ${instanceId} to IndexedDB`);
                    } catch (err) {
                        console.error("Failed to save initial checklist instance to IndexedDB:", err);
                    }
                }
                setLoadingMessage(''); // reset loading
            } catch (error) {
                console.error("Error fetching workable instance:", error);
                setLoadingMessage('');
            }
        };

        fetchData();
    }, [bpId, decodedData, isIndexedDBReady, localDecode, authUser]);

    const capturedImageData = (data) => {
        if ("cancelled" !== data) {
            // Make sure we have a valid instance ID
            if (!clInstance || !clInstance.id) {
                console.warn("Cannot save image: no valid checklist instance ID");
                return;
            }

            const instanceId = String(clInstance.id);
            const imageIdx = String(currentWebCamIndex);

            // Make sure the image data is valid
            if (!data || typeof data !== 'string' || !data.startsWith('data:image')) {
                console.error("Invalid image data received from webcam");
                return;
            }

            console.log(`Saving image for item ${imageIdx} in checklist ${instanceId}`);

            // First, update the local component state - make a clean copy of the array
            let images = [...imageArray];

            // Remove any existing entry with this ID
            images = images.filter(img => !(img && img.id === currentWebCamIndex));

            // Add the new image to the array
            images.push({
                id: currentWebCamIndex,
                img: data
            });

            // Update form value
            setValue(`items_with_meta_${currentWebCamIndex}[meta][imgUp]`, true);

            // Update image array in state
            setImageArray(images);

            // Force a UI refresh to ensure the image is displayed
            setForceRefresh(Date.now());

            // Store the image in IndexedDB
            if (isIndexedDBReady) {
                setImageData(instanceId, imageIdx, data)
                    .then((success) => {
                        if (success) {
                            console.log(`Successfully saved image to IndexedDB`);
                            saveChecklistLocally(false, false);
                        } else {
                            console.error("Failed to save image to IndexedDB - storage function returned false");
                            setHasUnsavedChanges(true);
                        }
                    })
                    .catch(err => {
                        console.error("Error saving image to IndexedDB:", err);
                        setHasUnsavedChanges(true);
                    });
            } else {
                console.error("IndexedDB not ready, cannot save image");
                setHasUnsavedChanges(true);
            }
        }

        setEnableWebCam(false);
    }
    const retake = (i) => {
        setEnableWebCam(true);
        setCurrentWebCamIndex(i);
    }
    const deletePhoto = (i = currentWebCamIndex) => {
        // Create a copy of the image array to work with
        const updatedImages = imageArray.filter(img => 
            img && img.id !== null && img.id !== i
        );

        // Clear the form field
        setValue(`items_with_meta_${i}[meta][imgUp]`, null);

        // Update the image array state with filtered array
        setImageArray(updatedImages);

        // Force an immediate UI refresh
        setForceRefresh(Date.now());

        setEnableWebCam(false);

        // Delete image from storage
        if (clInstance && clInstance.id) {
            const instanceId = String(clInstance.id);
            const imageIdx = String(i);

            if (isIndexedDBReady) {
                deleteImageData(instanceId, imageIdx)
                    .then(success => {
                        if (success) {
                            console.log(`Successfully deleted image ${instanceId}_${imageIdx} from IndexedDB`);
                            // Force another UI refresh after successful deletion from DB
                            setForceRefresh(Date.now());
                            // Mark as having unsaved changes
                            setHasUnsavedChanges(true);
                        } else {
                            console.warn(`Failed to delete image ${instanceId}_${imageIdx} from IndexedDB`);
                            setHasUnsavedChanges(true);
                        }
                    })
                    .catch(err => {
                        console.error(`Error deleting image ${instanceId}_${imageIdx} from IndexedDB:`, err);
                        setHasUnsavedChanges(true);
                    });
            } else {
                console.error("IndexedDB not ready, cannot delete image");
                setHasUnsavedChanges(true);
            }
        } else {
            console.warn("Cannot delete image: no valid checklist instance ID");
        }
    }

    /* Save the checklist data */
    useEffect(() => {
        if (isIndexedDBReady) {
            saveChecklistLocally(false, true);
        }
    }, [imageArray, clBP, clInstance, isIndexedDBReady]);

    const saveChecklistLocally = async (exitPage = false, silent = false) => {
        // Only proceed if we have a valid instance with an ID
        if (!clInstance || !clInstance.id) {
            console.warn("Cannot save checklist: missing instance or instance ID");
            return;
        }

        const instanceId = String(clInstance.id);
        let formData = getValues();

        /* If user clicks save before API response of BP, do nothing */
        if (!clBP || !formData) {
            console.warn("Cannot save checklist: missing blueprint or form data");
            return;
        }

        /* Prepare data for saving */
        //Since we are setting item[id] as index, there will be nulls in empty indexes,
        //we need to remove those and compact the array
        formData.items_with_meta = formData.items_with_meta?.filter(e => e !== null);

        try {
            // Get a clean valid images array - ensuring no null/deleted images are included
            const validImages = imageArray.filter(img =>
                img &&
                img.id !== null &&
                img.img !== null &&
                typeof img.img === 'string' &&
                img.img.startsWith('data:image')
            );

            console.log(`Saving checklist with ${validImages.length} valid images`);

            // Step 1: Store each valid image separately first
            if (!silent && validImages.length > 0) {
                for (const img of validImages) {
                    await setImageData(instanceId, String(img.id), img.img);
                }
            }

            // Step 2: Create the checklist data object with filtered image array
            const checklistData = {
                formData,
                imageArray: validImages, // Only include valid images
                clBp: clBP,
                clInstance,
                timestamp: new Date().toISOString()
            };

            // Step 3: Store the checklist data (with image references)
            await setChecklistData(instanceId, checklistData);
            setHasUnsavedChanges(false);

            // Update the UI to reflect the current state
            setForceRefresh(Date.now());
        } catch (error) {
            console.error("Failed to save checklist data:", error);
            setHasUnsavedChanges(true);
        }

        if (silent) return;

        /* Async queue for user feedback */
        setLoadingMessage("Saving");
        setTimeout(() => { setLoadingMessage('Saving images') }, 500);
        setTimeout(() => {
            setLoadingMessage('');
            if (exitPage) goBack();
        }, 1000);
    }

    const deleteSavedChecklist = async (exitPage = false) => {
        // Make sure we have a valid instance with an ID
        if (!clInstance || !clInstance.id) {
            console.warn("Cannot delete checklist: no valid instance ID");
            return;
        }

        const instanceId = String(clInstance.id);

        setLoadingMessage("Deleting local data");

        try {
            // Delete the checklist data using the IndexedDB-focused function
            await deleteChecklistData(instanceId);
            console.log(`Successfully deleted checklist ${instanceId}`);

            if (exitPage) {
                setTimeout(() => { setLoadingMessage('') }, 600);
                setTimeout(() => { goBack() }, 1000);
            } else {
                setLoadingMessage('');
            }
        } catch (error) {
            console.error(`Failed to delete checklist ${instanceId}:`, error);
            setLoadingMessage('Error deleting checklist data');

            // Wait a moment before clearing the error message
            if (exitPage) {
                setTimeout(() => {
                    setLoadingMessage('');
                    goBack();
                }, 1500);
            } else {
                setTimeout(() => { setLoadingMessage('') }, 1500);
            }
        }
    }

    const submitChecklist = (formData) => {
        setValidationError('');
        if (!formData) return;

        // Extract all items_with_meta keys
        const itemsWithMetaKeys = Object.keys(formData).filter(key => key.startsWith("items_with_meta_"));

        if (itemsWithMetaKeys.length === 0) {
            setValidationError('Please complete all required sections before submitting.');
            setLoadingMessage('');
            return;
        }

        // Go through each section to ensure it is opened and its fields are filled
        const invalidSections = clBP?.sections?.filter((section) => {
            const sectionItems = clBP?.items?.filter((item) => item?.pivot?.section == section.id);
            return sectionItems.some((item) => {
                const itemKey = `items_with_meta_${item.id}`;
                const formItem = formData[itemKey];

                // Check if the item is filled out properly (especially closing_option)
                const isInvalid = !formItem?.meta?.closing_option || formItem?.meta?.closing_option.trim() === "";

                if (isInvalid) {
                    console.log(`Item ${item.id} is invalid. Missing or empty 'closing_option'.`);
                }
                return isInvalid;
            });
        });
        
        if (invalidSections.length > 0) {
            // Auto-expand sections with errors
            const sectionsToExpand = invalidSections.map(section => 
                clBP?.sections?.findIndex(s => s.id === section.id)
            ).filter(index => index !== -1);
            
            // Add these sections to the openSectionIndex if they're not already open
            if (sectionsToExpand.length > 0) {
                setOpenSectionIndex(prevOpen => {
                    const newOpenSections = [...prevOpen];
                    sectionsToExpand.forEach(index => {
                        if (!newOpenSections.includes(index)) {
                            newOpenSections.push(index);
                        }
                    });
                    return newOpenSections;
                });
                
                // Trigger field-level validation after a short delay to ensure sections are expanded
                setTimeout(() => {
                    // Re-trigger validation on all fields to show field-level errors
                    trigger(); // This will trigger validation on all fields
                    
                    // Scroll to the error summary
                    const errorSummary = document.getElementById('error-summary');
                    if (errorSummary) {
                        errorSummary.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 100);
            }
            
            setValidationError('Please fill out all required fields before submitting.');
            setLoadingMessage('');
            return;
        }

        /* set it for submission */
        formData.checklist_type = "instance";
        formData.clInstance_id = clInstance?.id ?? 0;

        //Since we are setting item[id] as index, there will be nulls in empty indexes,
        //we need to remove those and compact the array
        formData.items_with_meta = formData.items_with_meta?.filter(e => e !== null);

        let imageArrayFormData = imageArray;
        imageArrayFormData = imageArrayFormData?.map((img, i) => { return { id: img.id, img: img.img } }).filter(e => e?.img !== null);
        imageArrayFormData = {
            clInstance_id: formData.clInstance_id,
            images: imageArrayFormData,
            ts_precise: new Date(),
            type: 'base64',
        }

        console.log('formData', formData);
        console.log('imageArrayFormData', imageArrayFormData);

        setLoadingMessage('Submitting Checklist');

        /* Convert items_with_meta_xyz to items_with_meta[] for API POST call */
        const items_with_meta = [];
        let formDataPostProc = { ...formData };
        for (const key in formDataPostProc) {
            if (key.startsWith("items_with_meta_")) {
                items_with_meta.push(formDataPostProc[key]);
                delete formDataPostProc[key];
            }
        }
        formDataPostProc.items_with_meta = items_with_meta;
        /* /end postProcessing of formData copy */

        const respSyncItems = axios.post("/checklist/sync-items", formDataPostProc).then(r => {
            console.log('cl/sync-items response', r.data);
            setLoadingMessage('Uploading Images');
            const respSyncImages = axios.post("/checklist/sync-images", imageArrayFormData, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    console.log('progressEvent', percentCompleted);
                    setLoadingMessage(`Uploading Images: ${percentCompleted}%`);
                }
            })
                .then(r => {
                    setHasUnsavedChanges(false);
                    setTimeout(() => {
                        setLoadingMessage('');
                        deleteSavedChecklist(false);
                        navigate(paths.checklist.done);
                    }, 500);
                }).catch(e => { setLoadingMessage('') });
        }).catch(e => { setLoadingMessage('') });
    }

    const renderMandatory = (bit, textOnly = false) => bit ? (textOnly ? ' *' : <span style={{ fontWeight: 800, color: "red" }}>*</span>) : '';

    const delimiter = ";";
    const basicValidations = {
        optionals: ['o', 'optional'],
        mandatory: ['m', 'mandatory'],
        mandatoryForTrigger: ['mft', 'mandatory-for-trigger']
    }

    const toggleSection = (index) => {
        setOpenSectionIndex((prevOpenSectionIndex) => {
            if (prevOpenSectionIndex.includes(index)) {
                return prevOpenSectionIndex.filter((i) => i !== index);
            } else {
                return [...prevOpenSectionIndex, index];
            }
        });
    };
    
    // Function to check if a section has validation errors
    const sectionHasErrors = (sectionId) => {
        if (!errors || Object.keys(errors).length === 0) return false;
        
        // Get items in this section
        const sectionItems = clBP?.items?.filter((item) => item?.pivot?.section == sectionId);
        if (!sectionItems || sectionItems.length === 0) return false;
        
        // Check if any item in this section has errors
        return sectionItems.some((item) => {
            const itemKey = `items_with_meta_${item.id}`;
            // Check for errors in closing_option or comments
            return errors[itemKey] || 
                   errors[`${itemKey}[meta][closing_option]`] || 
                   errors[`${itemKey}[meta][comment]`];
        });
    };
    
    const handleOptionChange = (formNameClosingOpt, value) => {
        setSelectedOptions((prev) => ({ ...prev, [formNameClosingOpt]: value }));
        setValue(formNameClosingOpt, value);
    };

    const RenderItem = (clItem, sectionId) => {
        const index = clItem.id;
        const random = Math.random();
        const imgAtt = clItem?.pivot?.meta?.img;
        const imgMandatory = !(basicValidations.optionals).includes(clItem?.pivot?.meta?.img_vl); //!optional
        const comAtt = clItem?.pivot?.meta?.com;
        const comMandatory = !(basicValidations.optionals).includes(clItem?.pivot?.meta?.com_vl); //!optional
        const formName = `items_with_meta_${index}`;
        const formNameSectionId = `${formName}[section]`;
        const formNameComment = `${formName}[meta][com]`;
        const formNameImgUser = `${formName}[meta][imgUp]`;
        const formNameClosingOpt = `${formName}[meta][closing_option]`;
        /* Check `Mandatory for Trigger Condition` * /
        if( getValues(formNameClosingOpt)===clItem?.trigger_on_option ) {
            console.log('matched case');
        }
        /* */
        const selectedClosingOpt = selectedOptions[formNameClosingOpt] || getValues(formNameClosingOpt) || '';
        const renderClosingOptionSelected = (formNameClosingOpt) => {
            const selectedClosingOpt = getValues(formNameClosingOpt);
            return (
                <span className={`p-t-b-5 p-l-r-10 d-b m-b-10 b-r-2 ${selectedClosingOpt ? 'bg-green-light' : 'bg-grey-light'}`}>
                    Your selection: {
                        selectedClosingOpt ?
                            <b>{selectedClosingOpt}</b> :
                            <span className={'f-s-09r'}>None</span>
                    }
                </span>
            );
        }


        return (
            <div key={index} style={{ order: clItem.pivot?.order ?? 1 }}>
                <Card>
                    <input name={formName + '[id]'} value={clItem?.id} {...register(formName + '[id]', { required: true })} className="form-input-hidden" />
                    <input name={formNameSectionId} value={sectionId} {...register(formNameSectionId, { required: true })} className="form-input-hidden" />

                    <div className={(imgAtt || imgMandatory) ? "grid-checklist" : "grid-checklist-no-img"}>
                        <div>
                            <h5>{clItem.name}</h5>
                            <textarea className="textarea textarea--transparent" rows="3" style={{ width: '100%' }}
                                name={formNameComment}
                                placeholder={"Enter Comments" + renderMandatory(comMandatory, true)}
                                {...register(formNameComment, { required: comMandatory, maxLength: 512 })}
                                onChange={(e) => setValue(formNameComment, e.target.value)}
                            />
                            <ErrorMessage errors={errors} name={formNameComment} render={({ message }) => <span className="inline-error inline-error-block">{message || "Comment Required"}</span>} />

                            {/* ===== USER SELECTION ===== */}
                            {/* Show user's selection for LocalDecode mode --- inputs were not working */}
                            {localDecode ? renderClosingOptionSelected(formNameClosingOpt) : <></>}
                            {/* @todo below - make defaultChecked/defaultSelected values for each input type */}

                            {/* Render Input conditionally */}
                            <div>
                                {(() => {
                                    switch (clItem?.closing_type) {
                                        default:
                                        case 'radio':
                                        case 'radio-button':
                                            return (
                                                clItem?.closing_options?.split(delimiter).map((option, i) => {
                                                    return (
                                                        <label className="p-t-b-5 m-r-20 flex-vert-baseline flex-inline" key={i}>
                                                            <Radio
                                                                name={formNameClosingOpt}
                                                                value={option}
                                                                checked={selectedClosingOpt === option}
                                                                modifier='material'
                                                                {...register(formNameClosingOpt, { required: "Closing Option Required" })}
                                                                onChange={(e) => handleOptionChange(formNameClosingOpt, e.target.value)}
                                                            />
                                                            <span className="p-l-5">{option}</span>
                                                        </label>
                                                    )
                                                })
                                            );
                                        case 'dropdown':
                                            return (
                                                <Select style={{ width: '100%', padding: '6px 10px' }}
                                                    modifier={'material'}
                                                    name={formNameClosingOpt}
                                                    value={selectedClosingOpt}
                                                    {...register(formNameClosingOpt, { required: "Closing Option Required" })}
                                                    onChange={(e) => {
                                                        handleOptionChange(formNameClosingOpt, e.target.value);
                                                    }}
                                                >
                                                    <option value={''} className="d-i-b p-t-b-5 p-l-r-10">Select</option>
                                                    {clItem?.closing_options?.split(delimiter).map((option, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={option}
                                                                className="d-i-b p-t-b-5 p-l-r-10"
                                                            >
                                                                {option}
                                                            </option>
                                                        )
                                                    })}
                                                </Select>
                                            );
                                        case 'checkbox':
                                            const selectedOptions = selectedClosingOpt ? selectedClosingOpt.split(delimiter) : [];
                                            return (<>
                                                <fieldset style={{ appearance: "none", border: 0, padding: 0, margin: 0 }}>
                                                    {clItem?.closing_options?.split(delimiter).map((option, i) => {
                                                        const isChecked = selectedOptions.includes(option);
                                                        return (
                                                            <label key={i} className="p-t-b-5 m-r-20 flex-vert-baseline flex-inline">
                                                                <Checkbox className="p-l-r-5"
                                                                    modifier={'material'}
                                                                    value={option}
                                                                    checked={isChecked}
                                                                    name={formNameClosingOpt}
                                                                    {...register(formNameClosingOpt, { required: "Closing Option Required" })}
                                                                    onChange={(e) => {
                                                                        let existing = getValues(formNameClosingOpt)?.split(delimiter) || [];
                                                                        const checked = !!(e.target.checked) ?? false;
                                                                        if (checked) {
                                                                            if (!existing.includes(e.target.value)) { existing.push(e.target.value) }
                                                                        } else {
                                                                            existing = existing.filter((arrEl) => arrEl !== e.target.value)
                                                                        }
                                                                        let newValue = existing.join(delimiter);
                                                                        if (delimiter === ';') newValue = newValue.replace(/^;+|;+$/g, '');
                                                                        if (delimiter === ',') newValue = newValue.replace(/^,+|,+$/g, '');
                                                                        handleOptionChange(formNameClosingOpt, newValue);
                                                                    }}
                                                                /> {option}
                                                            </label>
                                                        )
                                                    })}
                                                </fieldset>
                                            </>
                                            );
                                        case 'textfield':
                                            return (
                                                <input
                                                    name={formNameClosingOpt}
                                                    value={selectedClosingOpt}
                                                    {...register(formNameClosingOpt, { required: "Closing Option Required" })}
                                                    placeholder="Enter Details here..."
                                                    onChange={(e) => {
                                                        handleOptionChange(formNameClosingOpt, e.target.value);
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        padding: '12px',
                                                        fontSize: '16px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        boxSizing: 'border-box',
                                                        transition: 'border-color 0.2s ease-in-out',
                                                        outline: 'none',
                                                    }}
                                                    // Example of focus style
                                                    onFocus={(e) => {
                                                        e.target.style.borderColor = '#2196F3';
                                                    }}
                                                />
                                            );
                                    }
                                })()}
                                <ErrorMessage errors={errors} name={formNameClosingOpt} render={({ message }) => <span className="inline-error inline-error-block">{message}</span>} />
                            </div>
                        </div>


                        {/* Photo/Image */}
                        {(imgAtt || imgMandatory) &&
                            <div className="grid-checklist-content">
                                {/* Use a function to determine if an image exists for this item */}
                                {(() => {
                                    // Find image that matches this item's index
                                    const matchingImage = imageArray.find(x =>
                                        x && x.id !== null && x.img !== null &&
                                        x.id === index
                                    );

                                    if (matchingImage && matchingImage.img) {
                                        return (
                                            <>
                                                <div className="checklist-image-container">
                                                    <img
                                                        key={`img-${index}-${forceRefresh}`}
                                                        src={matchingImage.img}
                                                        alt="Captured"
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '200px',
                                                            objectFit: 'contain',
                                                            border: '1px solid #ddd',
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                </div>
                                                <div className="grid grid-2x text-a-center" style={{ marginTop: '10px' }}>
                                                    <Button modifier="quiet" onClick={() => retake(index)}>
                                                        <span className="fa fa-refresh"></span> Retake
                                                    </Button>
                                                    <Button modifier="quiet" onClick={() => deletePhoto(index)}>
                                                        <span style={{ color: 'red' }}><span className="fa fa-trash"></span> Remove</span>
                                                    </Button>
                                                </div>
                                            </>
                                        );
                                    }

                                    // If no image, show the take photo button
                                    return (
                                        imgAtt && (
                                            <div className="text-a-right w-100-pc">
                                                {/* Take Photo */}
                                                <Button modifier="quiet" onClick={() => retake(index)}>
                                                    <span className="fa fa-camera"></span> Take photo {renderMandatory(imgMandatory)}
                                                </Button>
                                                {/* Validation for Image */}
                                                <span>
                                                    <textarea
                                                        className="textarea textarea--transparent"
                                                        rows="1"
                                                        style={{ position: 'absolute', visibility: 'hidden', height: 0 }}
                                                        name={formNameImgUser}
                                                        {...register(formNameImgUser, { required: imgMandatory, maxLength: 1 })}
                                                    />
                                                    <ErrorMessage errors={errors} name={formNameImgUser} render={({ message }) => <span className="inline-error inline-error-block">{message || "Photo Required"}</span>} />
                                                </span>
                                            </div>
                                        )
                                    );
                                })()}
                                {enableWebCam && (index === currentWebCamIndex) &&
                                    <WebcamCapture capturedImageData={capturedImageData} clBP={clBP} />
                                }
                            </div>
                        }
                    </div>
                    {
                        (clItem?.meta && (clItem?.meta['trigger_job'] ?? false)) // isEnabled?
                        && clItem?.item_id // Valid ReqItem?
                        && String(getValues(formNameClosingOpt))?.split(delimiter).includes(clItem?.trigger_on_option) // Selected Trigger is same as Trigger Option?
                        && <>
                            <hr className="thin" />
                            <div className="checklist-item-footer">
                                <span>
                                    <span className="notification notification--material"><small>Info</small></span>{' '}
                                    <b>{clItem?.trigger_on_option}</b>{' '}
                                    triggers job <b>{clItem?.trigger_item?.name}</b>{' '}
                                    for department <b>{clItem?.trigger_item?.desk?.name}</b>
                                </span>
                            </div>
                            {/* Check if a similar job already exist and display radio buttons to decide what to do */}
                            <SimilarJobExists clItem={clItem} loc={decodedData?.lon} register={register} errors={errors} setValue={setValue} getValues={getValues} />
                        </>
                    }
                </Card>
                {/* Spacer */}
                <div className="d-b" style={{ padding: 0, paddingBottom: '1px', height: 0, margin: 0 }} />
            </div>
        );
    }

    const enableRenderId = true;
    const renderId = (str) => (enableRenderId && str) ? <span className="opa-050 p-l-r-5">(#{str})</span> : null;

    return (
        <>
            {/* Fullscreen Loading Messages */}
            {(loadingMessage) &&
                <div className="flex flex-both-center flex-dir-col h-100-vh flex-dir-col animate__animated" style={{ position: "absolute", width: "100%", background: "rgba(255,255,255,0.95)", zIndex: 100 }}>
                    <ProgressCircular modifier="material" indeterminate={true} />
                    <h3>{loadingMessage || "Fetching..."}</h3>
                </div>
            }
            <Page renderToolbar={() =>
                <Toolbar>
                    <div className="left">
                        <BackButton onClick={() => { saveChecklistLocally(true) }}>Back</BackButton>
                    </div>
                    <div className="center">
                        <small>{dateRT}</small>
                    </div>
                    <div className="right">
                        <span className="p-l-r-10 f-s-1r"><span className="fa fa-user-shield"></span>{' '}<b>{authUser?.fname || ''}</b></span>
                        {/* ACTIONS */}
                        {/*<Button modifier="quiet" onClick={()=>setShowOptions(true)}><span className="fa fa-cogs"></span></Button>*/}
                        {/*<label className="flex flex-both-center p-r-10" style={{justifyContent:'right'}}>
                    <Checkbox onChange={(event)=>{}} modifier='' /> {' '} Compact
                </label>*/}
                    </div>
                </Toolbar>}>
                {clBP &&
                    <form onSubmit={handleSubmit(submitChecklist)}>

                        <div className="p-l-r-15">

                            <div className="p-t-5 p-l-r-0">
                                <h3 className="p-0 m-0 text-a-left"><b>{clInstance?.name}</b>&nbsp;<small className="f-w-400">(ID: {clInstance?.id})</small></h3>
                                <div className="grid grid-3x grid-4x-responsive grid-gap-10 text-a-left flex-horiz-space-bw p-5">
                                    {clBP?.desk_id && <p className="p-0 m-0"><span className="fa fa-building-o p-r-2"></span>{' Department: '}{clBP?.desk?.name}</p>}
                                    {clBP?.id && <p className="p-0 m-0"><span className="fa fa-edit p-r-2"></span>{' Template: '}{clBP?.name}</p>}
                                    <p className="p-0 m-0"><span className="fa fa-clock-o p-r-2"></span>{' Validity: '}{makeTime(clInstance?.ts_start)} - {makeTime(clInstance?.ts_end)}</p>
                                    {loc && <p className="p-0 m-0"><span className="fa fa-map-marker-alt p-r-2"></span>{' Area/Location: '}{loc?.location}</p>}
                                </div>
                                <hr className="thin" />
                            </div>

                            <input name="clBp_id" value={bpId} {...register("clBp_id", { required: true })} className="form-input-hidden" />

                            {/* Error Summary - Show only when there are errors */}
                            {errors && Object.keys(errors).length > 0 && (
                                <div id="error-summary" style={{
                                    backgroundColor: '#ffebee',
                                    border: '1px solid #ff5252',
                                    borderRadius: '4px',
                                    padding: '10px',
                                    margin: '10px 0',
                                }}>
                                    <h4 style={{ color: '#d32f2f', margin: '6px 0' }}>
                                        <Icon icon="mdi:alert-circle" style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                        Checklist has unfilled items
                                    </h4>
                                    <p style={{ margin: '0 0 5px 0' }}>Please complete the required fields in these sections:</p>
                                    <ul style={{ margin: '0', paddingLeft: '20px' }}>
                                        {clBP?.sections?.map((section, idx) => {
                                            if (sectionHasErrors(section.id)) {
                                                return (
                                                    <li key={idx} style={{ marginBottom: '5px' }}>
                                                        <a 
                                                            href="#" 
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // Add this section to open sections if not already open
                                                                if (!openSectionIndex.includes(idx)) {
                                                                    setOpenSectionIndex(prev => [...prev, idx]);
                                                                }
                                                                // Scroll to the section
                                                                document.getElementById(`section-${idx}`).scrollIntoView({ 
                                                                    behavior: 'smooth',
                                                                    block: 'start'
                                                                });
                                                            }}
                                                            style={{ color: '#d32f2f', textDecoration: 'underline' }}
                                                        >
                                                            {section.name}
                                                        </a>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        }).filter(Boolean)}
                                    </ul>
                                </div>
                            )}

                            {clBP?.sections?.map((section, sectionIndex) => (
                                <div key={sectionIndex} id={`section-${sectionIndex}`} style={{ marginBottom: '20px' }}>
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            padding: '10px',
                                            background: openSectionIndex.includes(sectionIndex) ? 'transparent' : '#ffffff',
                                            borderRadius: '2px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            border: !openSectionIndex.includes(sectionIndex) 
                                                ? sectionHasErrors(section.id)
                                                    ? '1px solid #ff5252'
                                                    : '1px solid #ddd'
                                                : 'none'
                                        }}
                                        onClick={() => toggleSection(sectionIndex)}
                                    >
                                        <h3 style={{ margin: '10px 10px 10px' }}>
                                            {sectionIndex + 1}. {section.name}
                                            {/* Show error indicator if section has errors */}
                                            {sectionHasErrors(section.id) && (
                                                <span style={{ 
                                                    color: '#ff5252', 
                                                    marginLeft: '5px',
                                                    fontWeight: 'bold',
                                                    fontSize: '1em'
                                                }}>*</span>
                                            )}
                                            {/* Show error message if section has errors and is collapsed */}
                                            {!openSectionIndex.includes(sectionIndex) && sectionHasErrors(section.id) && (
                                                <span style={{ 
                                                    color: '#ff5252', 
                                                    marginLeft: '10px',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8em'
                                                }}>
                                                    <Icon icon="mdi:alert-circle" style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                                                    Has errors
                                                </span>
                                            )}
                                        </h3>
                                        <Icon icon={openSectionIndex.includes(sectionIndex) ? 'mdi:chevron-up' : 'mdi:chevron-down'} />
                                    </div>
                                    {openSectionIndex.includes(sectionIndex) && (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {clBP?.items?.filter((item) => item?.pivot?.section == section.id).map((clItem, clItemIndex) => (
                                                RenderItem(clItem, section.id)
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="cl-footer m-t-30 m-b-20">

                                <h5>Save locally</h5>
                                <div style={{ display: 'grid', gridGap: 20, gridTemplateColumns: 'repeat(2,1fr)' }}>
                                    {/* Save Button */}
                                    <div className="m-b-20 d-b">
                                        <Button modifier="large--cta" onClick={() => saveChecklistLocally(false)} style={{ backgroundColor: "#4d8722", borderColor: "#4d8722" }}>
                                            Save
                                        </Button>
                                    </div>
                                    {/* Delete Button */}
                                    <div className="m-b-20 d-b">
                                        <Button modifier="large--cta" disabled={!localDecode} onClick={() => deleteSavedChecklist(true)} style={{ backgroundColor: "#753020", borderColor: "#753020" }}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>

                                {/* Error exists ? */}
                                {(errors?.items_with_meta?.length ?? null) &&
                                    <span className="col-red p-b-5 d-b text-a-center f-s-09r">
                                        Please fill all required fields
                                    </span>
                                }
                                {validationError && (
                                    <span className="col-red p-b-5 d-b text-a-center f-s-09r">
                                        {validationError}
                                    </span>
                                )}
                                <Button modifier="large--cta" onClick={handleSubmit(submitChecklist)}>
                                    Submit Checklist
                                </Button>
                                {/* Logged in user info */}
                                {authUser && <div style={{ color: 'darkgray', textShadow: '0 1px 0 white', marginTop: 20, marginBottom: 0, textAlign: "center" }}>Submitting as {authUser?.name ?? authUser?.fname ?? 'Unknown'}</div>}
                            </div>

                        </div>
                        {/* Options */}
                        <ActionSheet isOpen={showOptions} isCancelable={false}>
                            <ActionSheetButton onClick={() => { }}>
                                Reset Form
                            </ActionSheetButton>
                            <ActionSheetButton onClick={() => { }}>
                                Mark all as `Yes`
                            </ActionSheetButton>
                            <ActionSheetButton onClick={() => setShowOptions(false)}>
                                Cancel
                            </ActionSheetButton>
                        </ActionSheet>
                    </form>
                }
                {/* Zero State */}
                {!clBP && <div className="flex flex-both-center flex-dir-col w-100-pc" style={{ height: '100%', minHeight: '80vh' }}>
                    {/* Loading */}
                    {!clInstanceNotFound && <ProgressCircular indeterminate />}
                    {/* NOT FOUND */}
                    {clInstanceNotFound &&
                        <div className="text-a-center" style={{ maxWidth: '86vw' }}>
                            <Lottie animationData={animInfoBlue} loop={true} style={{ width: 90, height: 90, margin: '0 auto' }} />
                            <h3 className="f-s-1-8r f-w-800">Valid checklist not found</h3>
                            <div className="w-100-pc d-b text-a-left">
                                <small className="d-b p-t-b-10 f-w-600 opa-050">QR SCAN DETAILS:</small>
                                <small className="d-b p-t-b-5">Selected Role: <b>{authUser?.roles?.find(role => role.id === parseInt(getStorage('u4R')))?.name ?? "Not Set"}</b>{renderId(getStorage('u4R'))}</small>
                                <small className="d-b p-t-b-5">Checklist: <b>{decodedData?.bpn}</b>{renderId(decodedData?.bpi)}</small>
                                <small className="d-b p-t-b-5">Location: <b>{decodedData?.lon}</b>{renderId(decodedData?.loi)}</small>
                            </div>
                            {
                                (!!lastWorkedInstance) &&
                                <small className="w-100-pc text-a-left d-b l-h-1r card-box-simple">
                                    Last submitted similar checklist:
                                    {lastWorkedInstance === 'not-found' ?
                                        <span className="d-b">Not found</span> :
                                        <ul className="p-l-20">
                                            <li>{lastWorkedInstance.name} (#{lastWorkedInstance.id})</li>
                                            <li>Generated at: {makeDate_Std(lastWorkedInstance.created_at)}</li>
                                            <li>Validity: {makeTime(lastWorkedInstance.ts_start ?? '')} - {makeTime(lastWorkedInstance.ts_end ?? '')}</li>
                                            <li>Actioned by: {lastWorkedInstance.actioned_by?.name ?? '-'}</li>
                                            {lastWorkedInstance?.actioned_by && <li>Actioned at: {makeDate_Std(lastWorkedInstance.user_actioned_at ?? lastWorkedInstance.updated_at)}</li>}
                                        </ul>
                                    }
                                </small>
                            }
                        </div>
                    }
                </div>
                }
            </Page>
        </>
    );
}

// React component to check for similar jobs based on item_id and loc,
// and prompt the user to trigger a duplicate job or not.
const SimilarJobExists = ({ clItem, loc, register, errors, setValue, getValues }) => {
    const item_id = clItem?.item_id;
    const index = clItem?.id
    const [similarJobs, setSimilarJobs] = useState([]);
    const [showSimilarJobs, setShowSimilarJobs] = useState(false);
    useEffect(() => {
        fetchSimilarJobs();
    }, [item_id, loc]);

    // Function to make the API call to check for similar jobs and update the state
    const fetchSimilarJobs = async () => {
        try {
            const response = await axios.get('/check-similar-jobs', {
                params: { item_id, loc },
            });
            console.log('Similar jobs API response:', response.data);
            setSimilarJobs(response.data.similarJobs);
        } catch (error) {
            console.error('Error checking similar jobs:', error);
        }
    };
    //Copy to clipboard function.
    function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Error copying text to clipboard: ', err);
            });
    }


    // Render content based on the length of similar jobs
    return similarJobs.length > 0 ? (
        <>
            <hr className="thin" />
            <div className="checklist-item-footer" style={{}}>
                <span>
                    <small>⚠️</small>{' '}<b>{similarJobs.length === 1 ? '1 similar work order' : similarJobs.length > 1 ? `${similarJobs.length} similar work orders exist` : null} in pending or parked state. Trigger Duplicate Work Order ?</b>
                </span>
                <div style={{ display: "grid", fontSize: "15px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                    {/* Radio button for Trigger Duplicate */}
                    <label style={{ display: "flex" }}>
                        <input
                            type="radio"
                            name={`items_with_meta_${index}.meta.duplicateOption`}
                            value="triggerDuplicate"
                            onClick={() => {
                                // Set latest_similar_job_id to null when latest_similar_job_id exists and 'Yes'(triggerDuplicate) is selected
                                if (getValues(`items_with_meta_${index}.meta.latest_similar_job_id`)) {
                                    setValue(`items_with_meta_${index}.meta.latest_similar_job_id`, null);
                                }
                            }}
                            required
                            {...register(`items_with_meta_${index}.meta.duplicateOption`, { required: "Trigger Duplicate Option Required" })}
                        />
                        Yes
                    </label>

                    {/* Radio button for Proceed Without Duplicate */}
                    <label style={{ display: "flex" }}>
                        <input
                            type="radio"
                            name={`items_with_meta_${index}.meta.duplicateOption`}
                            value="proceedWithoutDuplicate"
                            onClick={() => {
                                if (similarJobs.length > 0) {
                                    // Store the latest job ID from similar jobs if 'No' (proceedWithoutDuplicate) is selected
                                    setValue(`items_with_meta_${index}.meta.latest_similar_job_id`, similarJobs[0].id);
                                }
                            }}
                            required
                            {...register(`items_with_meta_${index}.meta.duplicateOption`, { required: "Trigger Duplicate Option Required" })}
                        />
                        No
                    </label>
                    <ErrorMessage errors={errors} name={`items_with_meta_${index}.meta.duplicateOption`} render={({ message }) => <span className="inline-error inline-error-block">{message}</span>} />
                </div>
            </div>
            <Button style={{ margin: '20px', display: 'flex', justifyContent: 'center', color: "white", backgroundColor: "#1f406b", borderRadius: "15px" }} onClick={() => setShowSimilarJobs(!showSimilarJobs)}>View Duplicate Jobs</Button>
            {showSimilarJobs && similarJobs.length > 0 && (
                <>
                    <div style={{ paddingBottom: "5px" }}><small>View Desktop App for more info.</small></div>
                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                        <table style={{ marginTop: '10px', borderCollapse: 'collapse', width: '100%', fontSize: '14px' }}>
                            {/* Render headers */}
                            <thead style={{ backgroundColor: "#84a0c4", color: "white" }}>
                                <tr>
                                    <th style={{ padding: '8px' }}>Job ID</th>
                                    <th style={{ padding: '8px' }}>Comment</th>
                                    <th style={{ padding: '8px' }}>Created On</th>
                                    <th style={{ padding: '8px' }}>Created By</th>
                                </tr>
                            </thead>
                            {/* Render rows */}
                            <tbody>
                                {similarJobs.map((job) => (
                                    <tr key={job.id}>
                                        <td style={{ padding: '10px', display: 'flex' }}>
                                            {job.id}
                                            <button
                                                type="button"
                                                onClick={() => copyToClipboard(job.id)}
                                                style={{
                                                    fontSize: '12px',
                                                    marginLeft: "5px",
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    backgroundColor: 'transparent'

                                                }}
                                            >
                                                <span className="fa fa-copy" />
                                            </button>
                                        </td>
                                        <td style={{ padding: '10px' }}>{job.comments ?? 'None'}</td>
                                        <td style={{ padding: '10px' }}>{job.created_at}</td>
                                        <td style={{ padding: '10px' }}>{job.fname}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    ) : null;
};


export const WebcamCapture = ({ capturedImageData, clBP }) => {
    const webcamRef = React.useRef(null);

    const [imgSrc, setImgSrc] = React.useState(null);
    const [enableWebcam, setEnableWebCam] = React.useState(true);
    const [isCapturing, setIsCapturing] = React.useState(false);

    const galleryUpload = clBP?.hotel?.settings?.find(setting => setting.key === "clUploadFromGallery")?.value ?? "";
    const isGalleryUploadEnabled = galleryUpload === true || galleryUpload === "true";

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const dataUrl = reader.result;
                if (dataUrl && typeof dataUrl === 'string' && dataUrl.startsWith('data:image')) {
                    console.log("File loaded successfully as data URL");
                    setImgSrc(dataUrl);
                    setEnableWebCam(false);
                } else {
                    console.error("Failed to load image from file correctly");
                }
            };
            reader.onerror = () => {
                console.error("Error reading file");
            };
            reader.readAsDataURL(file);
        }
    };

    const capture = React.useCallback((e) => {
        e.preventDefault(); // Prevents auto-submitting the checklist

        setIsCapturing(true);
        try {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc && typeof imageSrc === 'string' && imageSrc.startsWith('data:image')) {
                console.log("Image captured successfully from webcam");
                setImgSrc(imageSrc);
                setEnableWebCam(false);
            } else {
                console.error('Failed to capture valid image from webcam');
            }
        } catch (error) {
            console.error('Error capturing image:', error);
        } finally {
            setIsCapturing(false);
        }
    }, [webcamRef]);

    const retake = () => {
        setImgSrc(null);
        setEnableWebCam(true);
    }

    const cancelAction = () => {
        console.log("Cancelling webcam capture");
        capturedImageData("cancelled");
    }

    const usePhoto = () => {
        if (imgSrc && typeof imgSrc === 'string' && imgSrc.startsWith('data:image')) {
            console.log("Using captured photo");
            capturedImageData(imgSrc);
        } else {
            console.error('No valid image to use');
            cancelAction();
        }
    }

    const videoConstraints = {
        width: 800,
        height: 600,
        facingMode: "environment"
    };

    return (
        <div>
            {/* Image */}
            <div className="Page__fauxFullscreen" style={{ position: 'fixed', zIndex: 99999, top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.9)' }}>
                <Page>
                    <div className="flex flex-both-center flex-dir-col p-t-b-0 w-100-pc">
                        {enableWebcam &&
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                forceScreenshotSourceSize={true}
                                style={{ width: '100%', maxHeight: '70vh' }}
                            />
                        }
                        {!enableWebcam && imgSrc && (
                            <img
                                src={imgSrc}
                                alt="Captured"
                                style={{ width: '100%', maxHeight: '70vh', objectFit: 'contain' }}
                            />
                        )}
                    </div>
                </Page>

                {/* Action buttons */}
                <div className="p-10 p-t-0" style={{ position: 'absolute', bottom: '17%', zIndex: 999999, width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {enableWebcam && <>
                        <button
                            className="btn-black btn-over-image m-l-r-10"
                            onClick={capture}
                            disabled={isCapturing}
                        >
                            {isCapturing ? 'Capturing...' : 'Capture photo'}
                        </button>
                        {isGalleryUploadEnabled && (
                            <>
                                <button
                                    type="button"
                                    className="btn-black btn-over-image m-l-r-10"
                                    onClick={(e) => e.target.nextElementSibling.click()}
                                >
                                    Upload Photo
                                </button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </>
                        )}
                        <button className="btn-black btn-over-image m-l-r-10" onClick={cancelAction}>Cancel</button>
                    </>
                    }
                    {!enableWebcam && <>
                        <button className="btn-black btn-over-image m-l-r-10" onClick={retake}>Retake photo</button>
                        <button className="btn-black btn-over-image m-l-r-10" onClick={cancelAction}>Cancel</button>
                        <button className="btn-black btn-over-image btn-blue m-l-r-10" onClick={usePhoto}>Use photo</button>
                    </>}
                </div>
            </div>
        </div>
    );
};

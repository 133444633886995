import './App.css';
import './flexie.css';
import './Animations.css';
import './pages.fbs/Fbs.css';
import imgBgBlur from './assets/fbs/bial/bg-rays.png'; //bg-airport.jpg -- last good one: philip-oroni-EaFX0kRvXT8-unsplash-light.jpg
import imgBgBlurMobile from './assets/fbs/bial/bg-rays.png'; //runway.jpg
import React from 'react';
import { useEffect, useState } from 'react';
import { Page, Button, Input } from 'react-onsenui';
import Lottie from "lottie-react";
import animAtom from './assets/atom.json';
import animAtomLoaded from './assets/check-blue.json';

import { ChecklistMyInstances } from './pages.cl/MyInstances';
import { ChecklistMySavedDrafts } from "./pages.cl/MySavedDrafts";
import { ChecklistMySettings } from "./pages.cl/MySettings";

import { BrowserRouter as Router, Route, Routes, Link, Outlet, useNavigate, useLocation } from 'react-router-dom'

import ApiLoading, { axiosInstance as axios } from './utils/axios';
import { useForm } from 'react-hook-form';

import { getStorage, setStorage } from "./utils/storage";

/* Sound Effects */
import useSound from 'use-sound';
import sfxStart from './assets/start.mp3';

/* App Components & Helpers */
import { API_BASE, COMPANY_BRAND_NAME, getProductMetaObj } from "./config.common";
import { FbsLanding, FbsSetLocation } from "./pages.fbs/FbsLanding";
import { FbsLandingQR } from "./pages.fbs/FbsLandingQR";
import { ChecklistSheet } from "./pages.cl/ChecklistSheet";
import { ChecklistsDashboard } from "./pages.cl/ChecklistsDashboard";
import { ChecklistFilledScreen } from "./pages.cl/ChecklistFilledScreen";


function App() {

    return (
        <Router>
            <Routes>
                {/* Global */}
                <Route element={<GlobalShell />}>
                    <Route exact path={paths.root.path} element={<LoginScreen />} />
                    <Route exact path={paths.root.login} element={<LoginScreen />} />
                    {/* ============================ CL/CHECKLISTS ============================= */}
                    {/* CL: MOBILE */}
                    <Route element={<LoggedInUserShell />} path={paths.checklist.root}> {/* Layout Route */}
                        <Route element={<MobileViewShellDefault />}>
                            <Route path={paths.checklist.dash} element={<ChecklistsDashboard />} />
                            <Route path={paths.checklist.details} element={<ChecklistSheet />} />
                            <Route path={paths.checklist.myInstances} element={<ChecklistMyInstances />} />
                            <Route path={paths.checklist.myClDrafts} element={<ChecklistMySavedDrafts />} />
                            <Route path={paths.checklist.done} element={<ChecklistFilledScreen />} />
                            <Route path={paths.checklist.mySettings} element={<ChecklistMySettings />} />
                        </Route>
                    </Route>
                    {/* ========================== FBS/FEEDBACK SYSTEM ========================== */}
                    {/* FBS: TABLET */}
                    <Route element={<TabletViewShell />} path={paths.tabletView.root}> {/* Layout Route */}
                        <Route element={<LoggedInUserShell />}>
                            <Route path={paths.tabletView.fbs.setLocation} element={<FbsSetLocation />} />
                            <Route path={paths.tabletView.fbs.feedback} element={<FbsLanding key="fbsLandingTabX" />} />
                            <Route path={paths.tabletView.fbs.feedbackQR} element={<FbsLandingQR key="fbsLandingQRTabX" />} />
                        </Route>
                    </Route>
                    {/* FBS: MOBILE */}
                    <Route element={<MobileViewShell />} path={paths.mobileView.root}> {/* Layout Route */}
                        <Route path={paths.mobileView.fbs.feedback} element={<FbsLanding viewType="mobile" key="fbsLandingMobile" />} />
                        <Route path="*" element={<NotFound variant="mobile-public" />} />
                    </Route>
                    {/* Generic: 404 */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Router>
    );
}
export default App;

/* Custom Theme for FBS @todo: Fully migrate to config */
/* Not removed for backward compatibility */
const findTheTheme = () => {
    console.log("Setting theme");
    if (window.location.href.includes('adani') || window.location.href.includes('azurewebsites')) return "theme-custom-violet";
    return "";
}
// const themeCustomString = findTheTheme();

const GlobalShell = () => {

    const navigate = useNavigate();
    const location = useLocation();
    let themeToUse = findTheTheme();

    useEffect(() => {
        console.log('Location changed');
        // eslint-disable-next-line react-hooks/exhaustive-deps
        themeToUse = findTheTheme();
        console.log("Server: ", API_BASE, getProductMetaObj());
    }, [location]);

    return <>
        <div className={`app-container-global ${themeToUse}`} id="app-container-global">
            <Outlet />
            <ApiLoading />
        </div>
    </>
};

export const paths = {
    root: {
        path: '/',
        login: '/login'
    },
    tabletView: {
        root: '/t',
        fbs: {
            dash: '/t/dashboard',
            feedback: '/t/feedback',
            feedbackQR: '/t/feedback-qr-code',
            setLocation: '/t/set-location',
        }
    },
    mobileView: {
        root: '/m',
        fbs: {
            feedback: '/m/feedback',
        }
    },
    checklist: {
        root: '/cl',
        dash: '/cl/dashboard',
        details: '/cl/details',
        done: '/cl/done',
        list: '/cl/list',
        myInstances: '/cl/my/list',
        myClDrafts: '/cl/my/drafts',
        mySettings: '/cl/my/settings',
    }
}

/* Get default path to route to on Login */
const getLandingScreen = () => {
    let productObj = getProductMetaObj();
    console.log("Product config: ", productObj);
    switch (productObj?.product?.toLowerCase()) {
        case 'fbs':
        case 'feedback':
        case 'feedback-system':
            return paths.tabletView.fbs.setLocation;
        case 'cl':
        case 'checklist':
        case 'checklists':
        default:
            return paths.checklist.dash;
    }
}

const LoggedInUserShell = () => {

    const [authUser, setAuthUser] = useState(getStorage('u4'));
    const [isAuthenticated, setIsAuthenticated] = useState(authUser && authUser?.api_token);

    axios.defaults.headers.common = { 'Authorization': `Bearer ${authUser?.api_token}` }

    let navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) navigate(paths.root.login, { replace: false, data: 'unauthenticated' })
    }, []);

    return (
        <div className={`app-container-secure`}>
            <Outlet />
        </div>
    );
}

const TabletViewShell = () => {
    return (
        <div className="app-container-tablet bg-cover" style={{ backgroundImage: `url(${imgBgBlur})` }}>
            <div className="flex flex-both-center flex-dir-col h-100-vh w-100-pc" style={{ overflowY: "auto", overflowX: "hidden" }}>
                <Outlet />
            </div>
        </div>
    );
}

const MobileViewShell = () => {
    return (
        <div className="app-container-mobile bg-cover is-mobile w-100-pc" style={{ backgroundImage: `url(${imgBgBlurMobile})` }}>
            <Outlet />
        </div>
    );
}
const MobileViewShellDefault = () => {
    /* Used for checklists mobile */
    return (
        <div className="app-container-mobile is-mobile is-mobile-cl">
            <Outlet />
        </div>
    );
}

function LoginScreen(props) {

    const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm();

    const [altAnimation, setAltAnimation] = useState(false);

    const navigate = useNavigate();

    const apiLogin = async (e, p) => {
        const response = await axios.post('/auth', {
            u: e,
            p: p,
        });
        return response;
    };

    const [playStart] = useSound(sfxStart);
    const onSubmit = (formData) => {
        apiLogin(formData.u, formData.p).then(
            r => {
                setStorage('u4', r.data);
                console.log("USER SET:", r.data);
                const role = r.data?.roles[0]?.id ?? "0";
                console.log("USER's FIRST ROLE ID':", role);
                axios.defaults.headers.common = { 'Authorization': `Bearer ${r?.data?.api_token}` }
                /* Animate and navigate */
                setAltAnimation(true);
                setTimeout(() => { playStart() }, 250);
                setTimeout(() => {
                    /* Conditionally navigate to Landing screen */
                    navigate(getLandingScreen(), { replace: true });
                }, 2000);
            }
        );
    }

    const productName = getProductMetaObj()?.productName ?? null;
    const brandLine = productName ? productName + " by " + COMPANY_BRAND_NAME : COMPANY_BRAND_NAME;
    document.title = brandLine;

    return (
        <div key="loginScreen">
            <Page>
                <div className={'flex flex-both-center bg-dots'} style={{ minHeight: '100vh', width: '100%', overflowX: 'hidden', overflowY: "auto" }}>

                    <form style={{ textAlign: 'center' }} onSubmit={handleSubmit(onSubmit)}> {/* className={'bg-white b-r-10 app-shadow-default p-t-b-15 p-l-r-30'}*/}
                        <section className="flex flex-both-center">
                            <Lottie animationData={altAnimation ? animAtomLoaded : animAtom} loop={true} style={{ height: 200 }} />
                        </section>
                        <p className="p-b-20 p-t-0 m-0">
                            <b>{brandLine}</b> {/* Eg: Checklists by Vesta */}
                        </p>
                        <p>
                            <Input
                                {...register("u", { required: true, maxLength: 42 })}
                                onChange={(e) => { setValue('u', e.target.value) }}
                                modifier='underbar'
                                float
                                placeholder='Username' />
                            {errors.u && <span className="error-inline">Required</span>}
                        </p>
                        <p>
                            <Input
                                {...register("p", { required: true, maxLength: 42 })}
                                onChange={(e) => { setValue('p', e.target.value) }}
                                modifier='underbar'
                                type='password'
                                float
                                placeholder='Password' />
                            {errors.p && <span className="error-inline">Required</span>}
                        </p>
                        <p className='p-t-15'>
                            <Button id="btn-login" type="submit" tabindex="1" style={{ width: "80%", cursor: "pointer" }} onClick={handleSubmit(onSubmit)} className="theme-color theme-primary-bg">Sign in</Button> {/*onClick={performLoginCheck}*/}
                            <button type="submit" style={{ display: "none" }} data-info="For Form submission">LOGIN</button>
                        </p>

                        <p className="d-b p-t-40 opa-050">
                            <small>&copy; 2022 CheckInn Technologies</small>
                        </p>
                        <small style={{ display: 'none' }}>& pow<span></span>er<span></span>ed by @<span></span>th<span></span>inkd<span></span>j</small>
                    </form>
                </div>
            </Page>
        </div>
    )
}

const NotFound = (props) => {
    let navigate = useNavigate();
    const { variant } = props; // mobile-public || *
    const goBack = () => navigate(-1);
    const isPubFbSubmission = variant && variant === 'mobile-public';
    const renderActions = () => {
        if (isPubFbSubmission) return <></>;
        return (
            <div className="flex flex-both-center p-t-40">
                <Button modifier="quiet" onClick={goBack}>
                    Go back
                </Button>
                <span className="opa-050">|</span>
                <Button modifier="quiet" onClick={() => navigate('/')}>
                    Login
                </Button>
            </div>
        );
    }
    const renderText = () => {
        return (
            isPubFbSubmission ?
                <>
                    <h1 className="m-0 p-0">Thank you!</h1>
                    <p className="m-0 p-0">Your feedback was submitted</p>
                </>
                :
                <>
                    <h1 className="m-0 p-0">404</h1>
                    <p className="m-0 p-0">Page Not Found</p>
                </>
        );
    }
    return (
        <Page>
            <div className="flex flex-both-center h-100-vh flex-dir-col" style={{ backgroundColor: 'white' }}>
                {renderText()}
                {renderActions()}
            </div>
        </Page>
    );
}

import React, { useState, useRef, useEffect } from 'react';
import vmsg from 'vmsg';
import vmsgWasm from '../assets/fbs/vmsg.wasm'

const NewAudioRecorder = ({ setAudioBlob, setIsFormReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recording, setRecording] = useState(null);

  const recorder = useRef(null);

  useEffect(() => {
    recorder.current = new vmsg.Recorder({
      // wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm'
      wasmURL: vmsgWasm
    });
  }, []);

  const record = async () => {
    setIsLoading(true);

    if (isRecording) {
      try {
        const blob = await recorder.current.stopRecording();
        console.log(blob);
        setAudioBlob(blob);
        setIsFormReady(true);
        setRecording(URL.createObjectURL(blob));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
        setIsRecording(false);
      }
    } else {
      try {
        await recorder.current.initAudio();
        await recorder.current.initWorker();
        recorder.current.startRecording();
        setIsLoading(false);
        setIsRecording(true);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='audio-recorder-container'>
      <button onClick={record} disabled={isLoading} className={isRecording ? 'record-btn stop-rec' : 'record-btn start-rec'}>
        {isRecording ? "Stop" : "Record"}
      </button>
      <div>
        {recording &&
          <audio src={recording} controls></audio>
        }
      </div>
    </div>
  );
};

export default NewAudioRecorder;
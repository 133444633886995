// IndexedDB utility for storing checklist data and images
export const DB_NAME = 'VestaMobWebClDb';
const DB_VERSION = 1;
const CHECKLIST_STORE = 'checklists';
const IMAGE_STORE = 'images';

// Initialize the database
export const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error('IndexedDB error:', event.target.error);
      reject('Error opening IndexedDB');
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create object stores if they don't exist
      if (!db.objectStoreNames.contains(CHECKLIST_STORE)) {
        db.createObjectStore(CHECKLIST_STORE, { keyPath: 'id' });
      }

      if (!db.objectStoreNames.contains(IMAGE_STORE)) {
        const imageStore = db.createObjectStore(IMAGE_STORE, { keyPath: 'id' });
        imageStore.createIndex('checklistId', 'checklistId', { unique: false });
      }
    };
  });
};

// Get database connection
const getDB = async () => {
  return await initDB();
};

// Store checklist data
export const storeChecklist = async (id, data) => {
  try {
    // Ensure id is a string type for consistency
    const checklistId = String(id);

    const db = await getDB();
    const transaction = db.transaction([CHECKLIST_STORE], 'readwrite');
    const store = transaction.objectStore(CHECKLIST_STORE);

    // Create a deep copy of the data object to avoid modifying the original
    const dataToStore = JSON.parse(JSON.stringify(data));

    // Replace full image data with references to prevent duplication
    if (dataToStore.imageArray && Array.isArray(dataToStore.imageArray)) {
      // Create a list of image references instead of full image data
      dataToStore.imageReferences = dataToStore.imageArray
        .filter(img => img && img.id !== null && img.img !== null)
        .map(img => ({
          id: String(img.id),
          imageId: `${checklistId}_${String(img.id)}` // Reference to image in IMAGE_STORE
        }));

      // Remove full image data to save space
      delete dataToStore.imageArray;
    }

    // Create an object with the proper structure for IndexedDB
    const checklistObject = {
      id: checklistId,
      data: dataToStore,
      timestamp: Date.now()
    };

    return new Promise((resolve, reject) => {
      const putRequest = store.put(checklistObject);

      putRequest.onsuccess = () => {
        console.log(`Successfully stored checklist ${checklistId} in IndexedDB`);
        resolve(true);
      };

      putRequest.onerror = (event) => {
        console.error('Error storing checklist in IndexedDB:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Failed to store checklist:', error);
    return false;
  }
};

// Get checklist data
export const getChecklist = async (id) => {
  try {
    const checklistId = String(id);
    const db = await getDB();

    // Get the checklist first
    const checklistData = await new Promise((resolve, reject) => {
      const transaction = db.transaction([CHECKLIST_STORE], 'readonly');
      const store = transaction.objectStore(CHECKLIST_STORE);
      const request = store.get(checklistId);

      request.onsuccess = () => {
        if (request.result) {
          resolve(request.result);
        } else {
          resolve(null);
        }
      };

      request.onerror = (event) => {
        console.error('Error getting checklist:', event.target.error);
        reject(event.target.error);
      };
    });

    if (!checklistData) {
      return null;
    }

    // Create a deep copy of the data to modify
    const result = JSON.parse(JSON.stringify(checklistData.data));

    // If there are image references, prepare them for loading
    if (result.imageReferences && Array.isArray(result.imageReferences)) {
      // Add the checklistId to each reference to help with loading
      result.imageReferences = result.imageReferences.map(ref => ({
        ...ref,
        checklistId: checklistId
      }));
    }

    return result;
  } catch (error) {
    console.error('Failed to get checklist:', error);
    return null;
  }
};

// Delete checklist data
export const deleteChecklist = async (id) => {
  try {
    const checklistId = String(id);
    const db = await getDB();

    // First, get the checklist to find its image references
    const transaction1 = db.transaction([CHECKLIST_STORE], 'readonly');
    const checklistStore = transaction1.objectStore(CHECKLIST_STORE);

    const imageIds = await new Promise((resolve) => {
      const request = checklistStore.get(checklistId);

      request.onsuccess = () => {
        if (request.result && request.result.data && request.result.data.imageReferences) {
          // Extract image IDs to delete
          resolve(request.result.data.imageReferences.map(ref => ref.imageId));
        } else {
          resolve([]);
        }
      };

      request.onerror = () => {
        console.warn(`Failed to get checklist ${checklistId} for image cleanup`);
        resolve([]);
      };
    });

    // Now delete the checklist and its images in a new transaction
    const transaction2 = db.transaction([CHECKLIST_STORE, IMAGE_STORE], 'readwrite');
    const checklistStore2 = transaction2.objectStore(CHECKLIST_STORE);
    const imageStore = transaction2.objectStore(IMAGE_STORE);

    // Delete the checklist
    checklistStore2.delete(checklistId);

    // Delete associated images by ID
    for (const imageId of imageIds) {
      imageStore.delete(imageId);
    }

    // Also check for any images with this checklistId (fallback)
    const imageIndex = imageStore.index('checklistId');
    const imagesRequest = imageIndex.getAllKeys(checklistId);

    imagesRequest.onsuccess = () => {
      const additionalImageKeys = imagesRequest.result || [];
      for (const key of additionalImageKeys) {
        if (!imageIds.includes(key)) {
          imageStore.delete(key);
        }
      }
    };

    return new Promise((resolve) => {
      transaction2.oncomplete = () => {
        console.log(`Successfully deleted checklist ${checklistId} and ${imageIds.length} images`);
        resolve(true);
      };

      transaction2.onerror = (event) => {
        console.error('Error deleting checklist:', event.target.error);
        resolve(false);
      };
    });
  } catch (error) {
    console.error('Failed to delete checklist:', error);
    return false;
  }
};

// Store an image in IndexedDB
export const storeImage = async (checklistId, imageIndex, data) => {
  try {
    // Ensure consistent string IDs
    const clId = String(checklistId);
    const imgIndex = String(imageIndex);
    const imageId = `${clId}_${imgIndex}`;

    if (!data || typeof data !== 'string' || !data.startsWith('data:image')) {
      console.error(`Invalid image data for ${imageId}. Data must be a valid data URL.`);
      return false;
    }

    const db = await getDB();
    const transaction = db.transaction([IMAGE_STORE], 'readwrite');
    const store = transaction.objectStore(IMAGE_STORE);

    return new Promise((resolve, reject) => {
      const request = store.put({
        id: imageId,
        data: data,
        checklistId: clId,
        imageIndex: imgIndex,
        timestamp: new Date().toISOString()
      });

      request.onsuccess = () => {
        console.log(`Successfully stored image ${imageId} in IndexedDB`);
        resolve(true);
      };

      request.onerror = (event) => {
        console.error(`Error storing image ${imageId}:`, event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error(`Failed to store image for checklist ${checklistId}, index ${imageIndex}:`, error);
    return false;
  }
};

// Get image data
export const getImage = async (checklistId, imageId) => {
  try {
    const db = await getDB();
    const transaction = db.transaction([IMAGE_STORE], 'readonly');
    const store = transaction.objectStore(IMAGE_STORE);
    
    // Construct the composite key
    const fullImageId = `${checklistId}_${imageId}`;

    return new Promise((resolve, reject) => {
      const request = store.get(fullImageId);

      request.onsuccess = () => {
        if (request.result) {
          resolve(request.result.data);
        } else {
          resolve(null);
        }
      };

      request.onerror = (event) => {
        console.error('Error getting image:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Failed to get image:', error);
    return null;
  }
};

// Delete a specific image
export const deleteImage = async (checklistId, imageIndex) => {
  try {
    const db = await getDB();
    const transaction = db.transaction([IMAGE_STORE], 'readwrite');
    const store = transaction.objectStore(IMAGE_STORE);

    const imageId = `${checklistId}_${imageIndex}`;

    return new Promise((resolve) => {
      const request = store.delete(imageId);

      request.onsuccess = () => resolve(true);
      request.onerror = (event) => {
        console.error('Error deleting image:', event.target.error);
        resolve(false);
      };
    });
  } catch (error) {
    console.error('Failed to delete image:', error);
    return false;
  }
};

// List all checklists
export const getAllChecklists = async () => {
  try {
    const db = await getDB();
    const transaction = db.transaction([CHECKLIST_STORE], 'readonly');
    const store = transaction.objectStore(CHECKLIST_STORE);

    return new Promise((resolve, reject) => {
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result.map(item => ({ id: item.id, data: item.data, timestamp: item.timestamp })));
      };

      request.onerror = (event) => {
        console.error('Error getting all checklists:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Failed to get all checklists:', error);
    return [];
  }
};

// Fallback to localStorage if IndexedDB fails
export const handleStorageFailure = (operation, ...args) => {
  console.warn(`IndexedDB ${operation} failed, falling back to localStorage`);
  // The actual fallback logic will be implemented in the storage.js file
  return null;
};

// Clean up the database by removing invalid entries
export const cleanupDatabase = async () => {
  try {
    const db = await getDB();
    const transaction = db.transaction([CHECKLIST_STORE, IMAGE_STORE], 'readwrite');
    const checklistStore = transaction.objectStore(CHECKLIST_STORE);
    const imageStore = transaction.objectStore(IMAGE_STORE);

    // Get all checklists
    return new Promise((resolve, reject) => {
      const invalidIds = [];
      const checklistRequest = checklistStore.openCursor();

      checklistRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const item = cursor.value;

          // Check for invalid data structure
          if (!item || !item.data || !item.data.clInstance || !item.data.clInstance.id) {
            invalidIds.push(item.id);
            checklistStore.delete(item.id);
            console.log(`Removed invalid checklist entry with ID: ${item.id}`);
          }

          cursor.continue();
        } else {
          console.log(`Completed database cleanup, removed ${invalidIds.length} invalid entries`);
          resolve(invalidIds.length);
        }
      };

      checklistRequest.onerror = (event) => {
        console.error('Error during database cleanup:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Failed to clean up database:', error);
    return 0;
  }
};

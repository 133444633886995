import React, { useState, useEffect, useCallback, useMemo } from 'react';

export const useDate = (options = {}) => {
    const {
        interval = 1000, // Default update interval: 1 second
        format = 'time', // Default format: time only
        locale = navigator.language, // Default to browser's locale
        customFormat = null // Custom formatting function
    } = options;

    // Memoize the getDate function to prevent recreating it on each render
    const getDate = useCallback(() => {
        const now = new Date();
        
        // Predefined format options
        const hour12Options = {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        
        const customDateOptions = {
            hour12: true,
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        
        // If customFormat is provided and is a function, use it
        if (customFormat && typeof customFormat === 'function') {
            return customFormat(now);
        }
        
        switch (format) {
            case 'date':
                return now.toLocaleDateString(locale);
            case 'datetime':
                return now.toLocaleString(locale, hour12Options);
            case 'custom':
                return now.toLocaleString(locale, customDateOptions);
            case 'time':
            default:
                return now.toLocaleTimeString(locale, hour12Options);
        }
    }, [format, locale, customFormat]);

    const [now, setDate] = useState(getDate());

    useEffect(() => {
        // Only set up the interval if interval > 0
        if (interval <= 0) return;

        const timer = setInterval(() => {
            setDate(getDate());
        }, interval);

        return () => {
            clearInterval(timer);
        };
    }, [interval, getDate]); // Add dependencies for the effect

    return now;
};

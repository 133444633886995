import React, { useEffect, useState } from "react";
import { getStorage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import {
    BackButton,
    List,
    ListItem,
    Page,
    Toolbar,
    ProgressCircular,
    Button
} from "react-onsenui";
import { useDate } from "../useDate";
import { axiosInstance as axios } from "../utils/axios";
import { makeDate_Std } from "../utils/date-helper";
import { SwipeableDrawer, Stack, Divider, Chip } from '@mui/material';
import { paths } from "../App";

export const ChecklistMyInstances = (props) => {

    const [authUser, setAuthUser] = useState(getStorage('u4'));
    const dateRT = useDate();

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [disableLoadMore, setDisableLoadMore] = useState(false)
    const loadMore = (e) => {
        setPageNum(pageNum + 1);
    }

    /* Navigation */
    let navigate = useNavigate();
    const goBack = () => navigate(paths.checklist.dash, { replace: true });

    const renderListHeader = () => <></>;
    const renderListFooter = () => <></>;

    const [myInstances, setMyInstances] = useState(null);
    const getMyClInstances = async () => {
        if (!authUser || !authUser?.id) return;
        let r = await axios.post("/checklist/my/instances", { per_page: pageSize, page: pageNum });
        if (r && r.data) {
            setMyInstances(prev => prev ? prev.concat(r.data?.data) : r.data?.data);
            if (r.data.next_page_url === null) {
                setDisableLoadMore(true);
            }
        }
    }

    useEffect(() => {
        getMyClInstances().then();
    }, [pageNum]);


    const [clickedRow, setClickedRow] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = (data) => {
        setClickedRow(data);
        setIsDrawerOpen(true);
    }
    const onDrawerClose = () => {
        setIsDrawerOpen(false);
        setClickedRow(0);
    }

    return (<>
        <Page renderToolbar={() =>
            <Toolbar>
                <div className="left">
                    <BackButton onClick={goBack}>Back</BackButton>
                </div>
                <div className="center">
                    <small>{dateRT}</small>
                </div>
                <div className="right">
                    {/* RHS */}
                </div>
            </Toolbar>
        }>
            <div className="p-l-r-15 m-b-20 m-t-15">
                <h3 style={{ lineHeight: '1.32', borderLeft: '3px solid #1e88e5', paddingLeft: 12 }}>
                    <span>My Completed Checklists</span>
                    <small className="opa-050 d-b f-s-094r">Most Recently Completed</small>
                </h3>
                {((!myInstances) || (myInstances && !myInstances.length)) &&
                    <div className="flex flex-both-center" style={{ height: '100%', minHeight: '75vh' }}>
                        {!myInstances && <span><ProgressCircular modifier="material" indeterminate={true} /></span>}
                        {myInstances && !myInstances.length && <span>None found</span>}
                    </div>
                }

                {/* Has data ? */}
                {
                    (myInstances && myInstances?.length > 0) &&
                    <div>
                        <List
                            dataSource={myInstances}
                            renderHeader={renderListHeader}
                            renderFooter={renderListFooter}
                            renderRow={(data, i) => (
                                <ListItem key={i} modifier={(i === (myInstances?.length - 1)) ? 'longdivider' : null}
                                    style={{ borderBottom: '1px solid #cdcdcd' }} onClick={() => openDrawer(data)}>
                                    <div className="p-r-15">
                                        <strong>{i + 1}.</strong> {data?.name}
                                        <small className="d-b f-s-09r p-t-10 col-grey-dark">
                                            Submitted: {makeDate_Std(data.user_actioned_at ?? data.updated_at)}
                                            <br />
                                            ID: {data?.id}
                                        </small>
                                    </div>
                                </ListItem>
                            )}
                        />

                        {/* Load more */}
                        <div style={{ placeItems: "center", display: "flex", paddingTop: 16 }}>
                            <Button style={{ margin: "0 auto", display: "block" }} onClick={loadMore} disabled={disableLoadMore}>Load More..</Button>
                        </div>

                    </div>
                }

                {!clickedRow ? <></> :
                    <SwipeableDrawer
                        anchor="right"
                        open={isDrawerOpen}
                        onClose={onDrawerClose}
                        onOpen={() => {
                            setIsDrawerOpen(true)
                        }}
                    >
                        <div style={{ padding: 10, maxWidth: '100%' }}>
                            <RenderClDetails clickedRow={clickedRow} onClose={onDrawerClose} />
                        </div>
                    </SwipeableDrawer>
                }

            </div>
        </Page>
    </>);

}

const RenderClDetails = (props) => {
    const delimiter = ";";
    const renderClosingOptions = (closingOptions) => {
        if (!closingOptions) return <></>;
        const closingOptionsArray = closingOptions.split(delimiter);
        return (closingOptionsArray.map((opt, i) => {
            return (<Chip key={i} label={opt} color="primary" variant="outlined" size="small" sx={{ mr: '4px' }} />);
        }));
    }

    const renderItemDetailsCard = (item, index) => {
        return (
            <div style={{
                padding: 20,
                marginTop: 30,
                border: '1px solid rgba(0,0,0,0.05)',
                borderRadius: 5,
                lineHeight: '1.65'
            }}>
                <h3>{index + 1}. {(item.name)}</h3>
                <p><small>Closing
                    Option(s): </small><strong>{renderClosingOptions(item?.pivot?.meta?.closing_option)}</strong></p>
                <p><small>Comments:</small> {item?.pivot?.meta?.com ?? <em>None</em>}</p>
                {item?.pivot?.meta?.img &&
                    <div style={{ marginTop: 10 }}>
                        <a href={item.pivot.meta.img} target="_blank" rel="noreferrer">
                            <img src={item.pivot.meta.img} alt=""
                                style={{ borderRadius: 1, width: "100%", height: 200, objectFit: 'cover' }} />
                        </a>
                    </div>
                }

            </div>
        )
    }

    const renderClInstanceStatus = (status) => {
        return (<>
            {-1 === status && <b style={{ color: 'darkred' }}>MISSED</b>}
            {0 === status && <b style={{ color: 'goldenrod' }}>PENDING</b>}
            {1 === status && <b style={{ color: 'forestgreen' }}>COMPLETED</b>}
        </>
        );
    }

    const config = {
        itemGrid: props.layout === 'wide' ? 2 : 1,  // pass layout='wide' to render wide version
    }
    const [entityData, setEntityData] = useState({});
    useEffect(() => {
        const r = axios.get(`/checklist/get`, {
            params: {
                id: props.clickedRow.id,
                type: 'instance',
                org_id: props.clickedRow?.org_id,
            }
        }).then(d => {
            console.log(d.data);
            setEntityData(d.data)
        });

    }, [props.clickedRow]);

    return (
        <div style={{ padding: '0 15px' }}>
            {/* Back button */}
            <div className="left">
                <BackButton onClick={props.onClose}>Back</BackButton>
            </div>

            {(entityData?.status) ?
                <Stack direction="column" justifyContent="space-between" alignItems="center">

                    <div style={{ lineHeight: 1.75 }}>
                        <h3 title="Checklist name and server created-at time">{entityData?.name}</h3>
                        <p style={{ opacity: 0.9, fontSize: '0.9rem' }}>
                            From {entityData?.blueprint?.name}
                            {' '}&bull;{' '}
                            Created: {entityData?.created_at ?? 0}
                        </p>
                        {entityData?.actioned_by?.name && <p style={{ opacity: 0.9, fontSize: '0.9rem' }}>Submitted
                            by {entityData?.actioned_by?.name} at: {entityData?.user_actioned_at ?? entityData?.updated_at ?? 0}</p>}
                    </div>
                    <div>
                        <div style={{
                            border: '1px solid #dedede',
                            borderRadius: 5,
                            padding: 20,
                            marginLeft: 10,
                        }}>Status: {renderClInstanceStatus(entityData?.status)}</div>
                    </div>
                </Stack>
                : <></>
            }

            {/* No data ? or loading */}
            {!entityData?.items?.length && <div></div>}

            {/* Render items */}
            {
                entityData?.items?.length !== 0 && entityData?.blueprint?.sections?.map((section, indexSection) => {
                    return <div key={indexSection}>
                        {/* Divider */}
                        <Divider sx={{ mt: 8, mb: 1.5 }} textAlign="center">
                            <span className="checklist-section-divider-tag"
                                style={{
                                    backgroundColor: "#1f1f1f",
                                    color: "white",
                                    padding: 10,
                                    borderRadius: 5,
                                    fontSize: "large"
                                }}>
                                Section {indexSection + 1}{section.name ? ": " + section.name : ''}
                            </span>
                        </Divider>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(' + config.itemGrid + ',1fr)',
                            gridGap: 20
                        }}>
                            {
                                entityData?.items.filter((item, indexItem) => (+item?.pivot?.section) === (+section?.id))
                                    .map((item, indexItem) => {
                                        return (<div key={indexItem}>{renderItemDetailsCard(item, indexItem)}</div>)
                                    })
                            }
                        </div>
                    </div>
                })
            }
        </div>
    )
}

import * as IndexedDBStorage from './indexedDBStorage';

// Export cleanupDatabase function for use in components
export const cleanupIndexedDB = IndexedDBStorage.cleanupDatabase;

// Flag to check if IndexedDB is available
let isIndexedDBAvailable = false;

// Check if IndexedDB is supported
const checkIndexedDBSupport = async () => {
    if (typeof indexedDB === 'undefined') {
        return false;
    }

    try {
        await IndexedDBStorage.initDB();
        return true;
    } catch (error) {
        console.warn('IndexedDB not available:', error);
        return false;
    }
};

// Initialize support check
(async () => {
    isIndexedDBAvailable = await checkIndexedDBSupport();
    console.log(`Storage mode for checklists: ${isIndexedDBAvailable ? 'IndexedDB' : 'localStorage'} is primary`);
})();

export function getStorage(item) {
    let data = localStorage.getItem(item) || null;
    return JSON.parse(dec(data));
}

export function setStorage(item, value) {
    localStorage.setItem(item, enc(JSON.stringify(value)));
    return true;
}

export function deleteStorage(item) {
    localStorage.removeItem(item);
    return true;
}

const shouldEncode = () => process.env.NODE_ENV === 'production';

function utf8_to_b64(str) { return btoa(unescape(encodeURIComponent(str))); }
function b64_to_utf8(str) { return decodeURIComponent(escape(atob(str))); }

const enc = (s) => s ? (shouldEncode() ? utf8_to_b64(s) : s) : s;
const dec = (s) => s ? (shouldEncode() ? b64_to_utf8(s) : s) : s;


/* sauce: 2019 @thinkdj: Uppcoming localStorage code */

/* ====================================== */
/*   LocalStorage Quick SYNCHRONOUS API   */
/* ====================================== */

/* getItem() will get the item as it was saved (number, string, Object, array ...) */
export function getItem(keyStr, defaultValue = null) {
    let returnable = defaultValue;
    if (!keyStr) return returnable;
    try {
        let dataStr = dec(localStorage.getItem(keyStr));
        if (dataStr === null) return returnable;
        if (isJsonParseable(dataStr)) {
            returnable = JSON.parse(dataStr);
        } else {
            returnable = dataStr;
        }
    } catch (e) {
        console.error(`Error in getItem(${keyStr}): `, e);
    }
    return returnable;
}

/* setItem() can take any JS object, array, string, etc. and convert to a JSON string for localStorage storage */
export function setItem(keyStr, data) {
    let serializedData;
    if (data === undefined || data === null) {
        localStorage.removeItem(keyStr);
        return true;
    }
    if (typeof data === 'object') {
        serializedData = JSON.stringify(data);
    } else {
        serializedData = String(data);
    }
    try {
        localStorage.setItem(keyStr, enc(serializedData));
    } catch (e) {
        console.error(`Error in setItem(${keyStr}): `, e);
        return false;
    }
    return true;
}

export function removeItem(key) {
    localStorage.removeItem(key);
    return true;
}

export function removeItemsContaining(keyStr) {
    Object.keys(localStorage).filter(
        lsKey => lsKey.includes(keyStr)
    ).forEach(
        lsKey => localStorage.removeItem(lsKey)
    );
}

export function countItemsContaining(keyStr) {
    return Object.keys(localStorage).filter(
        lsKey => lsKey.includes(keyStr)
    ).length;
}

export function isJsonParseable(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/* ========================================== */
/*  CHECKLIST-SPECIFIC STORAGE API WITH IDB   */
/* ========================================== */

// Get checklist data - checks IndexedDB first and only falls back to localStorage for migration
export async function getChecklistData(id, defaultValue = null) {
    // Convert id to string for consistency
    const checklistId = String(id);

    // Try IndexedDB first if available
    if (isIndexedDBAvailable) {
        try {
            const data = await IndexedDBStorage.getChecklist(checklistId);
            if (data) {
                // If we have image references, load the actual images
                if (data.imageReferences && Array.isArray(data.imageReferences)) {
                    try {
                        // Load all images in parallel
                        const imagePromises = data.imageReferences.map(async ref => {
                            const imageData = await getImageData(checklistId, ref.id);
                            return imageData ? { id: parseInt(ref.id), img: imageData } : null;
                        });

                        // Wait for all images to load
                        const loadedImages = await Promise.all(imagePromises);
                        
                        // Filter out any null results and assign to imageArray
                        data.imageArray = loadedImages.filter(img => img !== null);
                        
                        // Delete the references as we don't need them anymore
                        delete data.imageReferences;
                    } catch (imageError) {
                        console.warn('Error loading images for checklist:', imageError);
                        data.imageArray = []; // Ensure we have an empty array if image loading fails
                    }
                } else {
                    data.imageArray = []; // Ensure we have an empty array if no images
                }

                console.log(`Successfully retrieved checklist ${checklistId} from IndexedDB`);
                return data;
            }
        } catch (error) {
            console.warn(`Failed to get data for checklist ${checklistId} from IndexedDB:`, error);
        }
    }

    // Only check localStorage for migration purposes
    const localData = getItem(`cache_instance_${checklistId}`, defaultValue);

    // If data was found in localStorage and IndexedDB is available, migrate it
    if (localData && isIndexedDBAvailable) {
        try {
            console.log(`Found legacy checklist data in localStorage for ${checklistId}, migrating to IndexedDB`);

            // Make sure the data has a valid structure
            if (localData.clInstance && localData.clInstance.id) {
                // Use the instance ID from the data for consistency
                const instanceId = String(localData.clInstance.id);

                // Log if there's an ID mismatch
                if (instanceId !== checklistId) {
                    console.warn(`ID mismatch during migration: requested=${checklistId}, actual=${instanceId}, using actual ID`);
                }

                await IndexedDBStorage.storeChecklist(instanceId, localData);
                console.log(`Successfully migrated checklist ${instanceId} from localStorage to IndexedDB`);

                // Try to clean up the localStorage entry after successful migration
                try {
                    removeItem(`cache_instance_${checklistId}`);
                    removeItem(`cache_instance_${checklistId}.formData`);
                    removeItem(`cache_instance_${checklistId}.imageArray`);
                    removeItem(`cache_instance_${checklistId}.clBp`);
                    removeItem(`cache_instance_${checklistId}.clInstance`);
                    removeItem(`cache_instance_${checklistId}.timestamp`);
                    console.log(`Cleaned up localStorage entries for checklist ${checklistId}`);
                } catch (cleanupError) {
                    console.warn(`Failed to clean up localStorage entries for checklist ${checklistId}:`, cleanupError);
                }
            } else {
                console.warn(`Invalid checklist data structure for ${checklistId}, skipping migration`);
            }
        } catch (error) {
            console.warn(`Failed to migrate checklist ${checklistId} from localStorage to IndexedDB:`, error);
        }
    }

    return localData || defaultValue;
}

// Store checklist data - use IndexedDB only
export async function setChecklistData(id, data) {
    // Convert id to string and verify data structure
    let checklistId = String(id);

    // If the data has a clInstance.id, use that for consistency
    if (data.clInstance && data.clInstance.id) {
        const instanceId = String(data.clInstance.id);

        // Log if there's an ID mismatch, but use the clInstance.id
        if (instanceId !== checklistId) {
            console.warn(`ID mismatch during save: supplied=${checklistId}, data.clInstance.id=${instanceId}, using data.clInstance.id`);
            checklistId = instanceId;
        }
    }

    // Save to IndexedDB if available
    if (isIndexedDBAvailable) {
        try {
            await IndexedDBStorage.storeChecklist(checklistId, data);
            return true;
        } catch (error) {
            console.error('Failed to store checklist data in IndexedDB:', error);
            return false;
        }
    } else {
        // For diagnostic purposes only
        console.error('IndexedDB not available for storing checklist data. This is not supported in newer versions.');
        return false;
    }
}

// Delete checklist data from both IndexedDB and localStorage (for cleanup)
export async function deleteChecklistData(id) {
    let success = true;

    // Delete from IndexedDB
    if (isIndexedDBAvailable) {
        try {
            success = await IndexedDBStorage.deleteChecklist(id);
        } catch (error) {
            console.error('Failed to delete checklist data from IndexedDB:', error);
            success = false;
        }
    }

    // Also clean up localStorage for backward compatibility
    try {
        removeItem(`cache_instance_${id}`);
        removeItem(`cache_instance_${id}.formData`);
        removeItem(`cache_instance_${id}.imageArray`);
        removeItem(`cache_instance_${id}.clBp`);
        removeItem(`cache_instance_${id}.clInstance`);
        removeItem(`cache_instance_${id}.timestamp`);
        removeItemsContaining(`cl_img_${id}_`);
    } catch (error) {
        console.warn('Failed to delete checklist data from localStorage:', error);
        success = false;
    }

    return success;
}

// Get image data - checks IndexedDB first and only falls back to localStorage for migration
export async function getImageData(checklistId, imageIndex, defaultValue = null) {
    // Ensure consistent string IDs
    const clId = String(checklistId);
    const imgIndex = String(imageIndex);

    // Try IndexedDB first
    if (isIndexedDBAvailable) {
        try {
            const imageData = await IndexedDBStorage.getImage(clId, imgIndex);
            if (imageData) {
                console.log(`Successfully retrieved image ${clId}/${imgIndex} from IndexedDB`);
                return imageData;
            }
        } catch (error) {
            console.warn(`Failed to get image ${clId}/${imgIndex} from IndexedDB:`, error);
        }
    }

    // Check localStorage only for migration purposes
    const localImageData = getItem(`cl_img_${clId}_${imgIndex}`, defaultValue);

    // If image was found in localStorage and IndexedDB is available, migrate it
    if (localImageData && isIndexedDBAvailable) {
        try {
            console.log(`Found legacy image data in localStorage for ${clId}/${imgIndex}, migrating to IndexedDB`);

            // Validate the data
            if (typeof localImageData === 'string' && localImageData.startsWith('data:image')) {
                await IndexedDBStorage.storeImage(clId, imgIndex, localImageData);
                console.log(`Successfully migrated image ${clId}/${imgIndex} from localStorage to IndexedDB`);

                // Try to clean up the localStorage entry after successful migration
                try {
                    removeItem(`cl_img_${clId}_${imgIndex}`);
                    console.log(`Cleaned up localStorage entry for image ${clId}/${imgIndex}`);
                } catch (cleanupError) {
                    console.warn(`Failed to clean up localStorage entry for image ${clId}/${imgIndex}:`, cleanupError);
                }
            } else {
                console.warn(`Invalid image data found in localStorage for ${clId}/${imgIndex}, skipping migration`);
            }
        } catch (error) {
            console.warn(`Failed to migrate image ${clId}/${imgIndex} from localStorage to IndexedDB:`, error);
        }
    }

    return localImageData || defaultValue;
}

// Store image data - use IndexedDB only
export async function setImageData(checklistId, imageIndex, imageData) {
    // Ensure consistent string IDs
    const clId = String(checklistId);
    const imgIndex = String(imageIndex);

    // Validate the data
    if (!imageData || typeof imageData !== 'string' || !imageData.startsWith('data:image')) {
        console.error(`Invalid image data for ${clId}_${imgIndex}`);
        return false;
    }

    // Store in IndexedDB
    if (isIndexedDBAvailable) {
        try {
            const success = await IndexedDBStorage.storeImage(clId, imgIndex, imageData);
            // Log with consistent format (using underscore which is how it's stored)
            console.log(`Successfully stored image ${clId}_${imgIndex} in IndexedDB`);
            return success;
        } catch (error) {
            console.error(`Failed to store image ${clId}_${imgIndex} in IndexedDB:`, error);
            return false;
        }
    } else {
        // For diagnostic purposes only
        console.error('IndexedDB not available for storing image data. This is not supported in newer versions.');
        return false;
    }
}

// Delete image data from both IndexedDB and localStorage (for cleanup)
export async function deleteImageData(checklistId, imageIndex) {
    // Ensure consistent string IDs
    const clId = String(checklistId);
    const imgIndex = String(imageIndex);

    // Delete from IndexedDB
    if (isIndexedDBAvailable) {
        try {
            const success = await IndexedDBStorage.deleteImage(clId, imgIndex);
            if (success) {
                console.log(`Successfully deleted image ${clId}_${imgIndex} from IndexedDB`);
                return true;
            } else {
                console.warn(`Failed to delete image ${clId}_${imgIndex} from IndexedDB`);
                return false;
            }
        } catch (error) {
            console.error(`Error deleting image ${clId}_${imgIndex} from IndexedDB:`, error);
            return false;
        }
    }

    // Also clean up localStorage for backward compatibility
    try {
        removeItem(`cl_img_${clId}_${imgIndex}`);
    } catch (error) {
        console.warn('Failed to delete image from localStorage:', error);
    }

    return true;
}

// Get all saved checklists - prioritize IndexedDB results but include localStorage for backward compatibility
export async function getAllSavedChecklists() {
    const results = new Map();

    // Try IndexedDB first if available
    if (isIndexedDBAvailable) {
        try {
            const idbChecklists = await IndexedDBStorage.getAllChecklists();

            if (idbChecklists.length > 0) {
                console.log(`Found ${idbChecklists.length} checklists in IndexedDB`);

                idbChecklists.forEach(item => {
                    // Ensure we're using the checklist's instance ID as the key
                    const instanceId = item.data?.clInstance?.id || item.id;

                    if (instanceId) {
                        const stringId = String(instanceId);

                        // Normalize timestamp to ensure it's a valid date
                        let timestamp;
                        try {
                            timestamp = item.timestamp ? new Date(item.timestamp) : new Date();
                            // Validate the date, if invalid, use current date
                            if (isNaN(timestamp.getTime())) {
                                timestamp = new Date();
                            }
                        } catch (e) {
                            timestamp = new Date();
                        }

                        results.set(stringId, {
                            id: stringId,
                            data: item.data,
                            timestamp: timestamp.toISOString(),
                            source: 'indexedDB'
                        });
                    }
                });

                // If we have checklists from IndexedDB, return them without checking localStorage
                if (results.size > 0) {
                    return Array.from(results.values());
                }
            }
        } catch (error) {
            console.warn('Failed to get checklists from IndexedDB, falling back to localStorage:', error);
        }
    }

    // Fall back to localStorage only if IndexedDB is unavailable or empty
    const localStorageKeys = Object.keys(localStorage);
    const clPrefixKeys = localStorageKeys.filter(key =>
        key.startsWith('cache_instance_') &&
        !key.includes('.') &&
        key !== 'cache_instance_' &&
        key !== 'cache_instance_undefined' &&
        key !== 'cache_instance_0'
    );

    if (clPrefixKeys.length > 0) {
        console.log(`Found ${clPrefixKeys.length} checklists in localStorage`);
    }

    for (const key of clPrefixKeys) {
        try {
            const keyId = key.replace('cache_instance_', '');
            const data = getItem(key);

            // Skip invalid or empty data
            if (!data || !data.clInstance) {
                console.warn(`Skipping invalid data for key ${key}`);
                continue;
            }

            // Use the instance ID from the data for consistency
            const instanceId = data.clInstance.id ? String(data.clInstance.id) : keyId;

            // Skip entries with invalid IDs
            if (!instanceId || instanceId === "undefined" || instanceId === "0") {
                console.warn(`Skipping checklist with invalid ID: ${instanceId}`);
                continue;
            }

            // Don't add duplicates
            if (results.has(instanceId)) {
                continue;
            }

            // Normalize timestamp
            let timestamp;
            try {
                // Look for timestamp in expected locations
                const rawTimestamp = data.timestamp ||
                    data.clInstance.updatedAt ||
                    data.clInstance.createdAt ||
                    null;

                timestamp = rawTimestamp ? new Date(rawTimestamp) : new Date();

                // Validate the date, if invalid, use current date
                if (isNaN(timestamp.getTime())) {
                    timestamp = new Date();
                }
            } catch (e) {
                timestamp = new Date();
            }

            results.set(instanceId, {
                id: instanceId,
                data: data,
                timestamp: timestamp.toISOString(),
                source: 'localStorage'
            });

            // Auto-migrate to IndexedDB if available - but don't await to keep this function fast
            if (isIndexedDBAvailable) {
                IndexedDBStorage.storeChecklist(instanceId, data).catch(err => {
                    console.warn(`Failed to auto-migrate checklist ${instanceId} during getAllSavedChecklists:`, err);
                });
            }
        } catch (error) {
            console.warn(`Error processing localStorage key ${key}:`, error);
        }
    }

    return Array.from(results.values());
}

// Migrate data from localStorage to IndexedDB and clean up localStorage after successful migration
export async function migrateToIndexedDB() {
    if (!isIndexedDBAvailable) {
        console.warn('IndexedDB not available, cannot migrate');
        return false;
    }

    try {
        console.log('Starting migration of checklists from localStorage to IndexedDB...');
        const localStorageKeys = Object.keys(localStorage);
        let migratedCount = 0;
        let cleanedUpCount = 0;

        // Track processed IDs to avoid duplicates
        const processedIds = new Set();

        // Only migrate checklist data keys (ones that don't have dots)
        const clPrefixKeys = localStorageKeys.filter(key =>
            key.startsWith('cache_instance_') &&
            !key.includes('.') &&
            key !== 'cache_instance_' &&
            key !== 'cache_instance_undefined' &&
            key !== 'cache_instance_0'
        );

        for (const key of clPrefixKeys) {
            try {
                const id = key.replace('cache_instance_', '');

                // Skip if we've already processed this ID
                if (processedIds.has(id)) {
                    console.log(`Skipping duplicate checklist ID ${id} during migration`);
                    continue;
                }

                const data = getItem(key);
                if (data) {
                    // Validate the data structure
                    if (!data.clInstance || !data.clInstance.id) {
                        console.warn(`Invalid checklist data structure for ${id}, skipping`);
                        continue;
                    }

                    // Ensure the extracted ID matches the data's internal ID for consistency
                    const dataId = String(data.clInstance.id);
                    if (id !== dataId) {
                        console.warn(`ID mismatch: key=${id}, data.clInstance.id=${dataId}, using data ID`);
                    }

                    // Use the data's internal ID for consistency
                    await IndexedDBStorage.storeChecklist(dataId, data);
                    processedIds.add(dataId);
                    migratedCount++;
                    console.log(`Migrated checklist ${dataId} to IndexedDB`);

                    // Clean up localStorage entries
                    try {
                        removeItem(key);
                        removeItem(`cache_instance_${dataId}.formData`);
                        removeItem(`cache_instance_${dataId}.imageArray`);
                        removeItem(`cache_instance_${dataId}.clBp`);
                        removeItem(`cache_instance_${dataId}.clInstance`);
                        removeItem(`cache_instance_${dataId}.timestamp`);
                        cleanedUpCount++;
                    } catch (cleanupError) {
                        console.warn(`Failed to clean up localStorage entries for checklist ${dataId}:`, cleanupError);
                    }
                }
            } catch (error) {
                console.warn(`Error migrating checklist ${key} to IndexedDB:`, error);
            }
        }

        // Migrate image data
        let migratedImageCount = 0;
        let cleanedUpImageCount = 0;
        const imgPrefixKeys = localStorageKeys.filter(key => key.includes('cl_img_'));

        // Track processed image IDs to avoid duplicates
        const processedImageIds = new Set();

        for (const key of imgPrefixKeys) {
            try {
                // Extract checklistId and imageIndex from key format: cl_img_[checklistId]_[imageIndex]
                const parts = key.split('_');
                if (parts.length >= 4) {
                    const checklistId = parts[2];
                    const imageIndex = parts[3];
                    const imageId = `${checklistId}_${imageIndex}`;

                    // Skip if we've already processed this image ID
                    if (processedImageIds.has(imageId)) {
                        console.log(`Skipping duplicate image ID ${imageId} during migration`);
                        continue;
                    }

                    const imageData = getItem(key);

                    if (imageData && typeof imageData === 'string' && imageData.startsWith('data:image')) {
                        await IndexedDBStorage.storeImage(checklistId, imageIndex, imageData);
                        processedImageIds.add(imageId);
                        migratedImageCount++;

                        // Clean up localStorage entry
                        try {
                            removeItem(key);
                            cleanedUpImageCount++;
                        } catch (cleanupError) {
                            console.warn(`Failed to clean up localStorage entry for image ${imageId}:`, cleanupError);
                        }
                    } else {
                        console.warn(`Invalid image data for ${imageId}, skipping migration`);
                    }
                }
            } catch (error) {
                console.warn(`Error migrating image ${key} to IndexedDB:`, error);
            }
        }

        console.log(`Migration completed: ${migratedCount} checklists and ${migratedImageCount} images migrated to IndexedDB`);
        console.log(`Cleaned up: ${cleanedUpCount} checklist entries and ${cleanedUpImageCount} image entries from localStorage`);
        return migratedCount > 0 || migratedImageCount > 0;
    } catch (error) {
        console.error('Failed to migrate data to IndexedDB:', error);
        return false;
    }
}

// Count all saved checklists - combines results from both IndexedDB and localStorage
export async function countAllSavedChecklists() {
    let count = 0;

    // Count from IndexedDB if available
    if (isIndexedDBAvailable) {
        try {
            const idbChecklists = await IndexedDBStorage.getAllChecklists();
            count += idbChecklists.length;
        } catch (error) {
            console.warn('Failed to count checklists from IndexedDB:', error);
        }
    }

    // Count from localStorage too (for backward compatibility)
    // Only count valid keys to avoid duplicates and invalid entries
    const localStorageKeys = Object.keys(localStorage);
    const validClKeys = localStorageKeys.filter(key =>
        key.startsWith('cache_instance_') &&
        !key.includes('.') &&
        key !== 'cache_instance_' &&
        key !== 'cache_instance_undefined' &&
        key !== 'cache_instance_0'
    );

    // If we have entries from IndexedDB, count only localStorage entries that aren't in IndexedDB
    if (count > 0 && isIndexedDBAvailable) {
        try {
            // Get all IndexedDB IDs
            const idbChecklists = await IndexedDBStorage.getAllChecklists();
            const idbIds = new Set(idbChecklists.map(item => {
                const id = item.data?.clInstance?.id || item.id;
                return String(id);
            }));

            // Count only localStorage entries that aren't in IndexedDB
            let additionalCount = 0;
            for (const key of validClKeys) {
                try {
                    const id = key.replace('cache_instance_', '');
                    // Skip if already counted in IndexedDB
                    if (!idbIds.has(id)) {
                        const data = getItem(key);
                        if (data && data.clInstance && data.clInstance.id) {
                            additionalCount++;
                        }
                    }
                } catch (error) {
                    // Skip invalid entries
                }
            }

            count += additionalCount;
        } catch (error) {
            // If comparison fails, just add localStorage count
            count += validClKeys.length;
        }
    } else {
        // If no IndexedDB entries, just count localStorage
        count += validClKeys.length;
    }

    return count;
}

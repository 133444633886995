import React, { useEffect, useState } from "react";
import { Button, AlertDialog, Switch } from 'react-onsenui';

import img5 from './../assets/fbs/default.5.png';
import img51 from './../assets/fbs/default.5.1.png';
import img4 from './../assets/fbs/default.4.png';
import img41 from './../assets/fbs/default.4.1.png';
import img3 from './../assets/fbs/default.3.png';
import img31 from './../assets/fbs/default.3.1.png';
import img2 from './../assets/fbs/default.2.png';
import img1 from './../assets/fbs/default.1.png';
import anim1 from "./../assets/fbs/animated-ratings/animated.1.json"
import anim2 from "./../assets/fbs/animated-ratings/animated.2.json"
import anim3 from "./../assets/fbs/animated-ratings/animated.3.json"
import anim4 from "./../assets/fbs/animated-ratings/animated.4.json"
import anim5 from "./../assets/fbs/animated-ratings/animated.5.json"

import Lottie from "lottie-react";
// import animAwaitingFeedback from "./../assets/fbs/fbs-awaiting-fb.json";
// import animGiftWaiting from "./../assets/fbs/fbs-gift-waiting.json";
import animFbFaces from "./../assets/fbs/feedback-faces-97309.json";
import animThankYouStar from "./../assets/fbs/fbs-star-thankyou.json";
import animFloatingFb from "./../assets/fbs/feedback-floating-115241.json";
import imgBgBlur from './../assets/fbs/bial/bg-rays.png'; //bg-airport.jpg -- last final = philip-oroni-EaFX0kRvXT8-unsplash-light.jpg
import imgBgBlurMobile from './../assets/fbs/bial/bg-rays.png'; //runway.jpg

import { paths } from "../App";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { axiosInstance as axios } from './../utils/axios';
import { axiosInstanceHttp as axiosExt } from './../utils/axios';

import { deleteStorage, getStorage, setStorage } from "../utils/storage";
import { Icon } from '@iconify/react';
import { ErrorMessage } from "@hookform/error-message";
import { decodeUrlHashString, getUrlParameterByName } from "../utils/helpers";
import { API_BASE, COMPANY_BRAND_NAME, PROFILE_IMG_FALLBACK, getEnvVar } from '../config.common';

/* Phone Number Input 
   [https://www.npmjs.com/package/react-phone-input-2] 
*/
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Autocomplete, TextField } from "@mui/material";
import { BlobServiceClient } from "@azure/storage-blob";
import NewAudioRecorder from "./NewAudioRecorder";

export const PAGE_INDEX = {
    RATING: 0,
    SECTION: 1,
    SUBSECTION: 2,
    SECTION_POSITIVE: 3,
    SUBSECTION_POSITIVE: 4,
    FEEDBACK: 5,
};

export function FbsLanding(props) {

    const viewType = props?.viewType ?? 'tablet'; // mobile/tablet
    const isMobile = viewType === 'mobile';
    const isTablet = viewType === 'tablet';

    const bgImage = isMobile ? imgBgBlurMobile : imgBgBlur;

    const config = {
        autoSubmitCertainRatings: true,
        autoSubmitRatingThreshold: 4,
        iconifyIconSize: isTablet ? 80 : 64,
        screenPauseTimeMs: 3000,
        enableEnticer: false,
    }

    const ratings = {
        three: [
            { score: 3, img: img5, name: "Excellent" },
            { score: 2, img: img3, name: "Average" },
            { score: 1, img: img2, name: "Poor" },
        ],
        generic: [
            { score: 1, img: img1, name: "Bad" },
            { score: 2, img: img2, name: "Poor" },
            { score: 3, img: img3, name: "Average" },
            { score: 4, img: img4, name: "Good" },
            { score: 5, img: img5, name: "Excellent" },
        ],
        gmrHyd: [
            { score: 5, img: img5, name: "Excellent", text: "Thank you for your feedback" },
            { score: 4, img: img41, name: "Very Good", text: "Thank you for your feedback" },
            { score: 3, img: img3, name: "Good", text: "Where can we improve?" },
            { score: 2, img: img31, name: "Fair", text: "How can we make things right?" },
            { score: 1, img: img2, name: "Poor", text: "How can we make things right?" },
        ],
        animated: [
            { score: 5, img: anim5, name: "Excellent", text: "Thank you for your feedback" },
            { score: 4, img: anim4, name: "Very Good", text: "Thank you for your feedback" },
            { score: 3, img: anim3, name: "Good", text: "Where can we improve?" },
            { score: 2, img: anim2, name: "Fair", text: "How can we make things right?" },
            { score: 1, img: anim1, name: "Poor", text: "How can we make things right?" },
        ],
    };

    /* Idle timer for tablet view */
    const timeout = 30000; // 30 seconds
    const onIdle = () => {
        if (!isTablet || viewIndex == 0) return
        resetPage();
        setIdle(false)
    }
    const [idle, setIdle] = useState(false);

    useEffect(() => {
        let timeoutId;

        const resetTimeout = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                setIdle(true);
                onIdle();
            }, timeout);
        };

        const handleUserActivity = () => {
            setIdle(false);
            resetTimeout();
        };

        resetTimeout();

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity);

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('touchstart', handleUserActivity);
            clearTimeout(timeoutId);
        };
    }, [timeout, onIdle]);

    /* Active Rating Context */
    const [ratingContext, setRatingContext] = useState({});


    useEffect(() => {
        setRatingContext({
            triggerThreshold: 3, // default `negative` review threshold
            triggerThresholdPositive: 3, // default `positive` review threshold
            ratingsTemplate: ratings.gmrHyd,
            keepAlivePing: 45, // minutes
        });
        document.title = `Feedback System by ${COMPANY_BRAND_NAME}`;
    }, []);

    const navigateStepWithRating = (step, currentRating) => navigateStep(step, null, null, currentRating, null);

    const [isGoingBack, setIsGoingBack] = useState(false);
    const navigateStep = (step, from = viewIndex, secIndex = stateSectionIndex, ratingCurrentSync = rating, secPosIndex = stateSectionPosIndex) => {
        setIsGoingBack(step < 0);
        if (step < 0) {
            switch (viewIndex) {
                case PAGE_INDEX.SECTION:
                    setSectionIndex(null)
                    break;
                case PAGE_INDEX.SUBSECTION:
                    setSubSectionIndex(null)
                    break;
                case PAGE_INDEX.SECTION_POSITIVE:
                    setSectionPosIndex(null)
                    break;
                case PAGE_INDEX.SUBSECTION_POSITIVE:
                    setSubSectionPosIndex(null)
                    break;
                case PAGE_INDEX.FEEDBACK:
                    reset()
                    break;
            }
        }
        console.log('isGoingBack?', isGoingBack);
        console.log('current rating', ratingCurrentSync, rating);
        let thresholdNegReview = parseInt(template?.meta?.threshold ?? ratingContext.triggerThreshold);
        let thresholdAutoSubmit = parseInt(template?.meta?.threshold_autoSubmit ?? config.autoSubmitRatingThreshold);
        let thresholdPosReview = parseInt(template?.meta?.threshold_positive ?? ratingContext.triggerThresholdPositive);
        console.log('thresholdNegReview', thresholdNegReview, 'thresholdAutoSubmit', thresholdAutoSubmit, 'thresholdPosReview', thresholdPosReview);
        let targetStep = from + (step);
        let ratingSkippable = ratingCurrentSync > thresholdNegReview
        let delighterSkippable = ratingCurrentSync < thresholdPosReview;
        console.log('from,to:', from, targetStep);
        console.log('ratingSkippable', ratingSkippable, 'delighterSkippable', delighterSkippable);

        /* Skip flow & Auto-submit ? */
        let autoSubmitDisabled = (0 === thresholdAutoSubmit);
        if (config.autoSubmitCertainRatings && (ratingCurrentSync >= thresholdAutoSubmit) && !autoSubmitDisabled) {
            console.log('Auto-submitting for rating', ratingCurrentSync, 'thresholdAutoSubmit:', thresholdAutoSubmit);
            setAutoSubmit(true);
            return;
        } else setAutoSubmit(false);

        switch (targetStep) {
            case PAGE_INDEX.RATING:
                delayedLoadView(PAGE_INDEX.RATING);
                break;
            case PAGE_INDEX.SECTION:
                if (ratingSkippable || !(template?.content?.sections?.length)) navigateStep(step, PAGE_INDEX.SECTION, null, ratingCurrentSync);
                else delayedLoadView(PAGE_INDEX.SECTION);
                break;
            case PAGE_INDEX.SUBSECTION:
                if (ratingSkippable || !(template?.content?.sections?.[secIndex]?.sections?.length)) navigateStep(step, PAGE_INDEX.SUBSECTION, null, ratingCurrentSync, secIndex);
                else delayedLoadView(PAGE_INDEX.SUBSECTION);
                break;
            case PAGE_INDEX.SECTION_POSITIVE:
                if (delighterSkippable || !(template?.content?.sectionsPositive?.length)) navigateStep(step, PAGE_INDEX.SECTION_POSITIVE);
                else delayedLoadView(PAGE_INDEX.SECTION_POSITIVE);
                break;
            case PAGE_INDEX.SUBSECTION_POSITIVE:
                if (delighterSkippable || !(template?.content?.sectionsPositive?.[secPosIndex]?.sections?.length)) navigateStep(step, PAGE_INDEX.SUBSECTION_POSITIVE);
                else delayedLoadView(PAGE_INDEX.SUBSECTION_POSITIVE);
                break;
            case PAGE_INDEX.FEEDBACK:
                delayedLoadView(PAGE_INDEX.FEEDBACK);
                break;
            default:
                console.log("INVALID PAGE-SWITCH CASE");
                break;
        }
    }

    const [isTransitioning, setIsTransitioning] = useState(false);
    const delayedLoadView = (viewIndex) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setViewIndex(viewIndex);
            setIsTransitioning(false);
        }, 125);
    }

    const [showEnticer, setShowEnticer] = useState(isTablet && config.enableEnticer);
    const [showThankYou, setShowThankYou] = useState(false);
    const [showBlockingLoader, setShowBlockingLoader] = useState(isMobile);

    const [rating, setRating] = useState(null);
    const [ratingIndex, setRatingIndex] = useState(null);
    const [stateSectionIndex, setSectionIndex] = useState(null);
    const [stateSubSectionIndex, setSubSectionIndex] = useState(null);
    const [stateSectionPosIndex, setSectionPosIndex] = useState(null);
    const [stateSubSectionPosIndex, setSubSectionPosIndex] = useState(null);

    const [user, setUser] = useState(getStorage('u4'));
    const [fbsMeta, setFbsMeta] = useState(getStorage('fbsMeta'));
    const [template, setTemplate] = useState(getStorage('template'));
    const [assocStaff, setAssocStaff] = useState(getStorage('assocStaff'));
    const [assocShift, setAssocShift] = useState(getStorage('assocShift'));
    const [viewIndex, setViewIndex] = useState(0);
    const [isFormReady, setIsFormReady] = useState(true);
    const [audioFeedback, setAudioFeedback] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioMimeType, setAudioMimeType] = useState('');
    const [mp3Blob, setMp3Blob] = useState(null);

    const [autoSubmit, setAutoSubmit] = useState(false);
    /* Auto-submit */
    useEffect(() => {
        if (!rating || !autoSubmit) return;
        onSubmit({ text: '', uMob: '', autoSubmit: true });
    }, [rating, autoSubmit]);

    const dismissEnticer = () => { resetPage(); setShowEnticer(false); };

    const setTheRating = (v) => setRating(v);
    const setTheRatingIndex = (v) => setRatingIndex(v);
    const resetPage = () => {
        reset();
        setRating(null); setRatingIndex(null); setRatingIndex(null); setSectionIndex(null); setSubSectionIndex(null); setSectionPosIndex(null); setSubSectionPosIndex(null);
        setViewIndex(0); setAutoSubmit(false);
        setIsFormReady(true);
    }

    const intervalDevicePing = React.useRef();
    useEffect(() => {
        if (!ratingContext || !ratingContext.ratingsTemplate) return;
        if (!fbsMeta || !fbsMeta.org_id) return;
        getTheTemplate().then(x => { });
        if (isTablet) {
            sendPing();
            let timeIntervalMins = parseInt(ratingContext?.keepAlivePing ?? 30);
            if (timeIntervalMins < 30) timeIntervalMins = 30;
            intervalDevicePing.current = setInterval(() => { sendPing() }, (timeIntervalMins) * 1000 * 60);
            console.log("Setting Ping Interval as", timeIntervalMins, "m");
        }
        return () => { clearInterval(intervalDevicePing.current) }
    }, [fbsMeta, ratingContext]);

    /* Mobile, URL param passed? Set fbsMeta */
    useEffect(() => {
        if (isMobile) {
            setShowBlockingLoader(true);
            let hash = getUrlParameterByName('hash', window.location);
            let decoded = decodeUrlHashString(hash);
            console.log('params', hash);
            console.log('decoded', decoded);
            if (!decoded || !decoded.oi) {
                setShowThankYou(true); // something is wrong OR user has already submitted
            };

            /* ======== Special Case ======== */
            /* On Checkinn (AWS), redirect Encalm request to Azure India Servers */
            if (window.location.hostname === 'fbs.checkinn.co' || window.location.hostname.endsWith('.checkinn.co')) {
                console.log("On CheckInn Main Server");
                let org_id = "" + decoded?.oi;
                if (['1071', '1072', '1073'].includes(org_id)) {
                    console.log("Redirecting for Encalm to Azure India Servers");
                    window.location.href = 'https://fbs.teamvesta.com/m/feedback?hash=' + hash; // fbs. = Azure Indian DB backend server
                    return;
                } else { console.log("No redirect"); }
            }
            /* ======== /Special Case ======== */

            window.history.replaceState({}, document.title, 'share-your-feedback'); // rewrite window.location
            /* Since we are using thin data without login API call, fill up essential data in same format as required by onSubmit */
            setFbsMeta({
                org_id: decoded?.oi,
                location: {
                    location: decoded?.lon,
                    id: decoded?.loi,
                }
            });
        }
    }, []);

    const sendPing = () => {
        if (!isTablet) return;
        console.log("Pinging...");
        axios.post('fbs/ping', { location_id: fbsMeta?.location?.id, org_id: fbsMeta?.org_id, device_id: fbsMeta?.tabUid + '-' + fbsMeta?.tabName || "Unnamed" }).then((r) => { })
    }

    const intervalRefetchTemplate = React.useRef();
    useEffect(() => {
        if (assocStaff?.id) {
            intervalRefetchTemplate.current = setInterval(getTheTemplate, 5 * 60 * 1000); // 5 mins
        }
        return () => {
            clearInterval(intervalRefetchTemplate.current);
        };
    }, [assocStaff]);

    const getTheTemplate = async () => {
        //let apiUrl = isMobile?"/fbs/public/get-the-template":"/fbs/get-the-template";
        console.log("Refreshing Feedback Template");
        let apiUrl = "/fbs/public/get-the-template"; // unified code
        let response = await axios.post(apiUrl, { location_id: fbsMeta?.location?.id, org_id: fbsMeta?.org_id });
        if (response?.data?.id === 0) {
            console.error("No associated templates found for " + fbsMeta?.location?.location + ". Using default");
        }
        else if (response && response.data) {
            console.log("Setting Feedback Template");
            setTemplate(response.data);
        }
        if (isMobile) {
            setStorage('u4', { 'hotel': response?.data?.org })
        }

        /* Set Associated Staff */
        if (response?.data?.location?.assoc_staff?.id ?? false) {
            setAssocStaff(response?.data?.location?.assoc_staff);
        } else setAssocStaff(null);
        /* Set Associated Shift */
        if (response?.data?.location?.assoc_shift?.id ?? false) {
            setAssocShift(response?.data?.location?.assoc_shift);
        } else setAssocShift(null);

        /* Turn loader off */
        setShowBlockingLoader(false);
        /* ===== CUSTOM THEME ====== */
        /* Org-specific processing */
        let orgNameString = ((response?.data?.org?.name || '') + " " + (response?.data?.org?.shortname || '')).toLowerCase();
        console.log("orgNameString", orgNameString);
        /* Higher order of precedence downwards; DO NOT USE `else if` */
        if (orgNameString.includes('bial')) { document.getElementById("app-container-global").classList.add('theme-custom-bial'); console.log("Setting theme: bial"); }
        if (orgNameString.includes('encalm')) { document.getElementById("app-container-global").classList.add('theme-custom-encalm-light'); console.log("Setting theme: Encalm.light"); }
        if (orgNameString.includes('mial')) { document.getElementById("app-container-global").classList.add('theme-custom-violet', 'theme-custom-mial'); console.log("Setting theme: MIAL"); }
        if (orgNameString.includes('vesta')) { // Dev Testing (Theme testing)
            //document.getElementById("app-container-global").classList.add('theme-custom-encalm-light');
        }
        /* ===== / CUSTOM THEME ====== */
    }

    /* Form Submit */
    const { register, handleSubmit, watch, setValue, getValues, reset, formState: { errors }, control } = useForm();

    const vestaIntegration = async (formDat) => {
        const settings = template?.org?.settings ?? {};
        let extVestaIntegration = settings?.find((x) => x.key === 'externalVestaIntegration')?.value ?? null;
        let extVestaOrgToken = settings?.find((x) => x.key === 'externalVestaOrgToken')?.value ?? null;
        let extVestaOrgId = settings?.find((x) => x.key === 'externalVestaOrgId')?.value ?? null;
        let extVestaBaseUrl = settings?.find((x) => x.key === 'externalVestaBaseUrl')?.value ?? null;

        if ((extVestaIntegration === "true") && (!!extVestaOrgToken && !!extVestaOrgId && !!extVestaBaseUrl)) {
            console.log("Vesta Integration Enabled")
            let resp = axiosExt.post(`${extVestaBaseUrl}api/v1/vesta/create`, { trig: 'fbs', user_id: 0, org_id: extVestaOrgId, org_token: extVestaOrgToken, item_id: formDat.item_id, comments: formDat.text || '', location: formDat.location });
        }
    }


    const uploadBlobToAzure = async (file) => {

        const accountKey = process.env.REACT_APP_AZINDBLOB_ACCOUNT_KEY
        const accountName = process.env.REACT_APP_AZINDBLOB_ACCOUNT_NAME
        const containerName = process.env.REACT_APP_AZINDBLOB_CONTAINER_NAME
        const sasToken = process.env.REACT_APP_AZINDBLOB_SAS_TOKEN

        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);

        const containerClient = blobServiceClient.getContainerClient(containerName);

        const blobName = file.name;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        try {
            // Upload the blob
            const options = { blobHTTPHeaders: { blobContentType: file.type } };

            await blockBlobClient.uploadBrowserData(file);

            const url = `https://vestaindia.blob.core.windows.net/${containerName}/${blobName}`;

            console.log('Blob uploaded successfully!');
            return url;
        } catch (error) {
            console.error('Error uploading blob:', error.message);
            return null;
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function () {
                var dataUrl = reader.result;
                resolve(dataUrl);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    function base64ToBlob(base64DataUrl) {
        const splitDataUrl = base64DataUrl.split(',');
        const contentType = splitDataUrl[0].split(':')[1].split(';')[0];
        const byteCharacters = atob(splitDataUrl[1]);
        const byteArrays = [];

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }

        const byteArray = new Uint8Array(byteArrays);
        return new Blob([byteArray], { type: contentType });
    }


    const transcribe = async (blob, fbsId) => {
        try {

            const base64Url = await blobToBase64(blob);
            let newBlob = base64ToBlob(base64Url);

            var file = new File([newBlob], `user_audio_${Date.now()}.webm`, {
                type: "audio/webm",
            });

            let audioUrl = await uploadBlobToAzure(file);

            console.log("file size", file.size);
            console.log("blob size", blob.size);
            console.log("file size", blob);


            const formData = new FormData();
            formData.append("file", file);
            formData.append("fbsId", fbsId);
            formData.append("audioUrl", audioUrl);
            // formData.append("mp3Audio", mp3Blob);
            // formData.append("base64Url", base64Url);

            let res = await axios.post("/fbs/public/transcribe", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log(res);
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = (formData, e) => {

        // Otp validation; verification
        let otpIsMandatory = (config_shouldValidateOtp && watch('uMob'))
        if (otpIsMandatory && !otpValidated) {
            setOtpValidated(false);
            setOtpValidationMessage("Please verify your mobile number using 'Validate Code'");
            return;
        }

        // Submit type
        let targetId = e?.target?.id ?? "fbs-main-submit";
        setIsFormReady(false);

        /* Org_id precedence: Login User's org ID first (templates shared b/w orgs use-case) >> fbsMeta org_id >> template's org_id */
        let org_id = user?.hotel_id ?? fbsMeta?.org_id ?? template?.org_id;

        console.log('formData passed', formData);
        formData.targetId = targetId;
        formData.org_id = org_id;
        formData.rating = rating;
        // if(!formData.text) formData.text="No comment";
        formData.location = template?.location?.location ?? fbsMeta?.location?.location ?? "Unknown";
        formData.location_id = fbsMeta?.location?.id ?? 0;
        formData.tablet_id = fbsMeta?.tabUid + '-' + fbsMeta?.tabName || "Unnamed"; // device_id
        formData.template_id = template?.id;
        formData.index_section = stateSectionIndex;
        formData.index_subSection = stateSubSectionIndex;
        formData.section = { ...template?.content?.sections[stateSectionIndex] };
        delete formData.section.sections;
        formData.subSection = { ...template?.content?.sections[stateSectionIndex]?.sections[stateSubSectionIndex] };
        delete formData.subSection.sections;

        formData.index_sectionPositive = stateSectionPosIndex;
        formData.index_subSectionPositive = stateSubSectionPosIndex;
        formData.sectionPositive = template?.content?.sectionsPositive?.length ? { ...template?.content?.sectionsPositive[stateSectionPosIndex] } : {};
        delete formData.sectionPositive.sections;
        formData.subSectionPositive = template?.content?.sectionsPositive?.length ? { ...template?.content?.sectionsPositive[stateSectionPosIndex]?.sections[stateSubSectionPosIndex] } : {};
        formData.item_id = formData.subSection?.item_id;
        delete formData.subSectionPositive.sections;

        if (Object.keys(formData.section).length) {
            formData.type = "Issue";
        } else if (Object.keys(formData.sectionPositive).length) {
            formData.type = "Delighters";
        } else {
            formData.type = "Unknown"; // default value in case neither section nor sectionPositive has data
        }

        if (formData.autoSubmit) {
            /* FIX: If user rates 1, reaches end, then navigates back and clicks 5, the autoSubmit would also submit Issue Section, subSection selected previously */
            delete formData.section;
            delete formData.subSection;
            delete formData.index_section;
            delete formData.index_subSection;
            delete formData.sectionPositive;
            delete formData.subSectionPositive;
            delete formData.index_sectionPositive
            delete formData.index_subSectionPositive
        }
        if (isMobile) {
            console.log('onSubmit: isMobile');
            formData.tablet_id = "Mobile";
            formData.device_id = window.navigator.userAgent;
        }

        // Add Associated Staff details
        if (assocStaff?.id) {
            formData.assocStaff = assocStaff;
        }

        console.log('formData processed', formData);
        // let apiUrl = isMobile?"/fbs/public/create":"/fbs/create";
        const apiUrl = "/fbs/public/create"; // unified code

        const afterAPICall = (flow = "default", res = null) => {

            if (res && audioBlob != null) {
                transcribe(audioBlob, res.id);
            }

            setShowEnticer(isTablet && config.enableEnticer);
            setShowThankYou(true);
            let screenOverlayTime = ("giveMoreFeedback" === flow) ? 0 : config.screenPauseTimeMs;
            setTimeout(() => {
                if ("default" === flow) {
                    resetPage();
                    if (isTablet) setShowThankYou(false);
                    setShowEnticer(isTablet && config.enableEnticer);
                }
                else if ("giveMoreFeedback" === flow) {
                    setShowThankYou(false);
                    setShowEnticer(false);
                    if (rating >= config.autoSubmitRatingThreshold) {
                        navigateStepWithRating(+3, rating)
                    }
                    else {
                        navigateStepWithRating(+1, rating)
                    }
                    setValue("text", "");
                    setIsFormReady(true);
                }
            }, screenOverlayTime);
            resetOTPState();
        }

        /* * /
        if(targetId === "fbs-main-submit") {
           axios.post(apiUrl,formData).then( (r) => {afterAPICall()}).catch(e=>{afterAPICall()});
        }
        else if(targetId === "fbs-give-more") {
            axios.post(apiUrl,formData).then( (r) => {afterAPICall("giveMoreFeedback"); }).catch(e=>{afterAPICall("giveMoreFeedback")});
        }
        // External Vesta Integration call
        vestaIntegration(formData).then(r => {console.log("Vesta Integration Response",r)}).catch(e=>{console.log("Vesta Integration Error",e)});
        /* */

        let axiosPromise;
        let afterAPICallArg = "";

        if (targetId === "fbs-main-submit") { axiosPromise = axios.post(apiUrl, formData); afterAPICallArg = "default"; }
        else if (targetId === "fbs-give-more") { axiosPromise = axios.post(apiUrl, formData); afterAPICallArg = "giveMoreFeedback"; }

        if (axiosPromise) {
            axiosPromise.then((r) => {
                console.log("Result Data: ", r.data)
                afterAPICall(afterAPICallArg, r.data);
                return vestaIntegration(formData);
            })
                .then((r) => {
                    console.log("Vesta Integration Response", r);
                })
                .catch((e) => {
                    console.log("Vesta Integration Error", e);
                });
        }

    }

    const getRatingHeadingText = () => <>{rating && ratingContext?.ratingsTemplate[ratingIndex]?.text}</>
    const getRatingSectionText = () => <>What needs improvement?</> /* old: Where can we improve our services? */
    const getRatingSubSectionText = () => <>What needs improvement?</>
    const getDelighterSectionText = () => <>What did you like about our services?</>
    const getDelighterSubSectionText = () => <>What did you like about our services?</>

    const settings = template?.org?.settings ?? [];
    const config_tnc = settings?.find((x) => x.key === 'fbsSubmitTnc')?.value ?? null;
    const config_privacy = settings?.find((x) => x.key === 'fbsPrivacyPolicy')?.value ?? null;
    const config_shouldValidateOtp = (settings?.find((x) => x.key === 'fbsOTPValidation')?.value === "true" && settings?.find((x) => x.key === 'fbsOTPValidationProvider')?.value) ?? false;
    const config_items_color = settings?.find((x) => x.key === 'fbsItemsColor')?.value ?? null;
    const config_hide_location = settings?.find((x) => x.key === 'fbsHideLocationOnTablet')?.value ?? null;
    const config_animated_rating = settings?.find((x) => x.key === 'fbsAnimatedRatings')?.value ?? null;
    const config_audio_feedback = settings?.find((x) => x.key === 'audioFeedback')?.value ?? null;

    let isAnimatedRatings = false
    if (config_animated_rating == "true") {
        isAnimatedRatings = true
    }

    const [otpSentMessage, setOtpSentMessage] = useState('');
    const [otpSentSuccessfully, setOtpSentSuccessfully] = useState(false);
    const [otpButtonDisabled, setOtpButtonDisabled] = useState(false);
    const [otpEnablementSecRemaining, setOtpEnablementSecRemaining] = useState(15);
    const [otpTimer, setOtpTimer] = useState(false); // OTP countdown timer for enable/disable
    const RenderGenerateOTP = () => {

        useEffect(() => {
            if (!otpTimer) return;
            let intervalId = null;
            if (otpButtonDisabled) {
                intervalId = setInterval(() => {
                    setOtpEnablementSecRemaining((prevSeconds) => prevSeconds - 1);
                }, 1000);
            }
            return () => {
                if (intervalId) clearInterval(intervalId);
            };
        }, [otpButtonDisabled]);
        useEffect(() => {
            if (otpEnablementSecRemaining <= 0) {
                setOtpButtonDisabled(false);
                setOtpEnablementSecRemaining(15);
            }
        }, []); // [otpEnablementSecRemaining]

        const generateAndSendOTP = () => {
            if (!watch('uMob') || watch('uMob')?.length < 10) { /* 10 is random; To account for +1 5003299 etc. too */
                setOtpSentMessage("✖ Please enter a valid mobile number");
                return;
            }
            setOtpSentSuccessfully(false);
            axios.post("/user-otps/generate-otp", {
                org_id: template?.org_id,
                phone: watch('uMob'),
            }).then(r => {
                console.log("Feedback Code Generated and sent");
                setOtpSentMessage("✔ Feedback Code was sent to the mobile number");
                setOtpSentSuccessfully(true);
                setOtpButtonDisabled(true);
                if (!otpTimer) setTimeout(() => setOtpButtonDisabled(false), 750); // Prevents multiple clicks (rudimentary) 
            }).catch((e) => {
                console.log("Feedback Code Generation Error");
                // setOtpSentMessage("✖ Error generating Feedback Code. Please recheck the phone number and try again.");
                // setOtpSentMessage("✖ Error: Notification provider server failure.");
                setOtpSentSuccessfully(true);
                setOtpButtonDisabled(false);
            });
        }
        return (
            <Button onClick={generateAndSendOTP} modifier="large--cta" className="button-otp button-otp-send theme-color theme-secondary-bg" style={{ minWidth: 125 }} disabled={!isFormReady || otpButtonDisabled}>
                Send Code {(otpButtonDisabled && otpTimer) && <span>({otpEnablementSecRemaining})</span>}
            </Button>
        );
    }

    const [otpValidated, setOtpValidated] = useState(false);
    const [otpValidationMessage, setOtpValidationMessage] = useState('');
    const RenderOTPValidator = () => {

        const validateOTP = () => {
            axios.post("/user-otps/validate-otp", {
                org_id: template?.org_id,
                phone: watch('uMob'),
                otp: watch('otp'),
            }).then(r => {
                setOtpValidated(true);
                setOtpValidationMessage("✔ Feedback Code successfully validated");
            }).catch(e => {
                setOtpValidated(false);
                setOtpValidationMessage("✖ Error validating Feedback Code. Please recheck and try again");
            });
        }

        const otpMandatoryCondition = (config_shouldValidateOtp && watch('uMob')) ?? false;

        return (
            <div style={{ position: 'relative' }}>
                <small className="fbs-form-input-label">Feedback Code {otpMandatoryCondition ? <em className="form-required">*</em> : <></>}</small>
                <input name="otp" type="number" className="fbs-form-input fbs-form-text-input" placeholder={"Enter Feedback Code"}
                    {...register("otp", { required: otpMandatoryCondition, minLength: 4, maxLength: 6 })}
                />
                {errors?.otp && <span className="error-inline">Please validate the Feedback Code</span>}

                <div className="otp-validate-btn-container">
                    <Button onClick={validateOTP} modifier="large--cta" className="button-otp button-otp-validate theme-color theme-secondary-bg" style={{ minWidth: 125 }} disabled={!isFormReady || !otpSentSuccessfully}>Validate Code</Button>
                </div>

                {/* OTP Validation message */}
                {otpValidationMessage && <span className={otpValidated ? "success-inline" : "error-inline"}>{otpValidationMessage}</span>}

            </div>
        );
    }

    /* Reset OTP state to defaults (after success) */
    const resetOTPState = () => {
        setOtpSentMessage('');
        setOtpSentSuccessfully(false);
        setOtpButtonDisabled(false);
        setOtpEnablementSecRemaining(15);
        setOtpValidated(false);
        setOtpValidationMessage('');
    }

    const RenderTncHtml = () => {

        const handleChange = (e) => {
            console.log("TnC checked", e.target.checked)
        }

        return (
            <div style={{ width: '100%', paddingTop: '10px' }}>
                <p dangerouslySetInnerHTML={{ __html: config_tnc }} className="open-links-in-new-tab" />
                <label className="tnc-label">
                    <input type="checkbox" {...register("tncCheck", { required: true })} />
                    {' '}{' '}
                    I agree to the Terms and Conditions
                </label>
                {(errors?.tncCheck) && <span className="error-inline">T&C acceptance required</span>}
            </div>
        )
    }

    const RenderPrivacyHtml = () => {
        return (
            <div style={{ width: '100%', paddingTop: '10px' }}>
                <p dangerouslySetInnerHTML={{ __html: config_privacy }} className="open-links-in-new-tab" />
            </div>
        )
    }

    const RenderFeedbackForm = () => {
        /* References */
        return (
            <div style={{ width: '100%', paddingTop: '10px' }} className={`animate__animated text-a-center ${isGoingBack ? 'animate__slideInLeft' : 'animate__slideInRight'}`}>
                <form className="fbs-form fbs-submit-form bg-glass" onSubmit={handleSubmit(onSubmit)}>
                    <div className="fbs-form-section-sub">
                        <h3 className="theme-color theme-primary">
                            {/*{getRatingHeadingText()}*/}
                            We would love to hear from you {/* Changed to static; for Encalm Req */}
                        </h3>
                        {/* Comment */}
                        <div className="feedback-text">
                            {audioFeedback ? <small className="fbs-form-input-label">Your Feedback / Suggestions in your <b>preferred language</b> <em>(Optional)</em></small> :
                                <small className="fbs-form-input-label">Your Feedback / Suggestions <em>(Optional)</em></small>}
                            {(config_audio_feedback === "true") ?
                                <span className="audio-toggle">{"   "}<Switch checked={audioFeedback} onChange={() => setAudioFeedback(!audioFeedback)} /><span>Audio Feedback</span></span>
                                : <></>
                            }

                        </div>
                        {audioFeedback ?
                            <div id="audio-feedback">
                                <NewAudioRecorder setAudioBlob={setAudioBlob} audioBlob={audioBlob} setIsFormReady={setIsFormReady} />
                            </div>
                            : <>
                                <textarea name="text" id="" rows="2" className="fbs-form-input fbs-form-textarea isAnimated" placeholder="Help us serve you better"
                                    {...register("text", { required: false, minLength: 0, maxLength: 2048 })}
                                />
                                {errors?.text && <span className="error-inline">Minimum 10 characters</span>}
                            </>
                        }

                        {/* Contact [Optional/Conditionally rendered] */}
                        {(template?.meta?.uMob ?? false) &&
                            <div style={{ position: "relative" }}>
                                <small className="fbs-form-input-label">Your Contact Number {(template?.meta?.uMobMan ?? false) ? <em className="form-required">*</em> : <em>(Optional)</em>} </small>
                                <Controller
                                    name="uMob"
                                    control={control}
                                    rules={{ required: (template?.meta?.uMobMan ?? false) }}
                                    render={({ field: { onChange, value, formattedValue } }) => (
                                        <PhoneInput
                                            id="uMob"
                                            inputStyle={{ background: "rgb(0,0,0,0.1)", border: "none", height: "50px", width: "100%" }}
                                            containerStyle={{ border: "none", height: "50px" }}
                                            value={formattedValue}
                                            onChange={(value, country, e, formattedValue) => {
                                                if (country?.dialCode === value || "+" === country?.dialCode) {
                                                    return (onChange(''))
                                                } else {
                                                    return (onChange(formattedValue))
                                                }
                                            }}
                                            placeholder={"Enter your mobile number"}
                                            enableSearch={true}
                                            searchPlaceholder={"Search country"}
                                            disableSearchIcon={true}
                                            country="in"
                                            preferredCountries={["in", "us", "gb", "sg", "au"]}
                                            excludeCountries={[""]}
                                            disabled={otpValidated}
                                        />
                                    )}
                                />

                                {/* ================= OTP ================= */}
                                {/* OTP message */}
                                {otpSentMessage && <span className={otpSentSuccessfully ? "success-inline" : "error-inline"}>{otpSentMessage}</span>}
                                {/* OTP Validation UI */}
                                {config_shouldValidateOtp ?
                                    <div className="otp-verify-btn-container">
                                        <RenderGenerateOTP />
                                    </div> : <></>
                                }
                                {/* ================= /OTP ================= */}
                            </div>
                        }

                        {/* <input type="number" className="fbs-form-input isAnimated" placeholder=""
                               {...register("uMob", {required:false, minLength:10, maxLength:20})}
                        /> */}
                        {errors.uMob && <span className="error-inline">Must be between 10 to 20 digits</span>}
                    </div>

                    {/* ================= OTP ================= */}
                    {config_shouldValidateOtp ? <RenderOTPValidator /> : <></>}
                    {/* ================= /OTP ================= */}

                    {/* User's Name and Email 50/50 Layout */}
                    {
                        <div className="fbs-form-section-sub">
                            <div className="fbs-2x-grid">
                                {/* Name */}
                                {(template?.meta?.uName ?? false) &&
                                    <div>
                                        <small className="fbs-form-input-label">Your Name {(template?.meta?.uNameMan ?? false) ? <em className="form-required">*</em> : <em>(Optional)</em>}</small>
                                        <input name="name" type="text" className="fbs-form-input fbs-form-text-input"
                                            {...register("uName", { required: (template?.meta?.uNameMan ?? false), maxLength: 96, minLength: 3, pattern: /^[a-zA-Z.' ]+$/ })}
                                        />
                                        {errors?.uName?.type === "required" && <span className="error-inline">Name Required</span>}
                                        {errors?.uName?.type === "pattern" || errors?.uName?.type === "minLength" && <span className="error-inline">Invalid Name</span>}
                                    </div>
                                }
                                {/* Email */}
                                {(template?.meta?.uEmail ?? false) &&
                                    <div>
                                        <small className="fbs-form-input-label">Your Email ID {(template?.meta?.uEmailMan ?? false) ? <em className="form-required">*</em> : <em>(Optional)</em>}</small>
                                        <input name="name" type="text" className="fbs-form-input fbs-form-text-input"
                                            {...register("uEmail", { required: (template?.meta?.uEmailMan ?? false), maxLength: 96, pattern: /^\S+@\S+$/i })}
                                        />
                                        {errors?.uEmail?.type === "required" && <span className="error-inline">Email Required</span>}
                                        {errors?.uEmail?.type === "pattern" && <span className="error-inline">Invalid Email</span>}
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {/* TnC */}
                    {(config_tnc !== null) ?
                        <RenderTncHtml />
                        : <></>
                    }

                    <div className="fbs-form-section-sub" style={{ marginTop: 30 }}>
                        {/* Button1 */}
                        {/* <Button type="submit" id="fbs-give-more" onClick={handleSubmit(onSubmit)} modifier="large--cta" className="theme-color theme-secondary-bg" disabled={!isFormReady}>
                            Give more feedback
                        </Button> */}

                        <a
                            href="#"
                            id="fbs-give-more"
                            onClick={isFormReady ? handleSubmit(onSubmit) : (e) => e.preventDefault()}
                            className="theme-color theme-secondary-bg "
                            style={{
                                pointerEvents: !isFormReady ? 'none' : 'auto',
                                opacity: !isFormReady ? 0.5 : 1
                            }}
                            disabled={!isFormReady}
                        >
                            Give Additional feedback
                        </a>

                        <div style={{ marginTop: "20px" }}>
                            {/* Button2 */}
                            <Button type="submit" id="fbs-main-submit" modifier="large--cta" onClick={handleSubmit(onSubmit)} className="theme-color theme-primary-bg" disabled={!isFormReady}>
                                Submit feedback
                            </Button>
                        </div>
                    </div>
                    {/* Privacy Policy */}
                    {(config_privacy !== null) ?
                        <RenderPrivacyHtml /> : <></>
                    }
                </form>

            </div>
        )
    }

    return (
        <div className={`ratings-container`}>

            <RenderHeader logo={template?.org?.logo_img ?? ''} />

            {/* Location has associated staff ? */}
            {assocStaff?.id ?
                <div className={`fbs-staff-on-duty__rating ${isMobile ? 'fbs-staff-on-duty__isMobile' : ''}`} title={(assocStaff?.name ?? assocStaff?.username ?? '') + " @ " + (assocShift?.name ?? '')}>
                    <img src={assocStaff?.avatar ?? PROFILE_IMG_FALLBACK} alt="Staff" className="fbs-loc-staff-profile-img fbs-loc-staff-profile-img-rating" />
                    <h6 className="fbs-staff-on-duty__rating-header">{assocStaff?.fname}</h6>
                </div>
                : <></>
            }

            <div className={`flex flex-both-center flex-dir-col ${isTransitioning ? 'is-transitioning' : ''}`}>

                {/* Ratings */}
                {viewIndex === PAGE_INDEX.RATING &&
                    <div className={`animate__animated text-a-center ${isGoingBack ? 'animate__slideInLeft' : 'animate__slideInRight'}`}>
                        <h3 className="theme-color theme-primary fbs-rating-heading">
                            Please rate your experience
                            {!config_hide_location &&
                                <small className="d-b p-t-b-10 fbs-header-location col-dark opa-050">{getLocationIcon()} {template?.location?.location ?? fbsMeta?.location?.location ?? getLocationNameLS()}</small>
                            }
                        </h3>
                        <div className="fbs-rating-blocks fbs-ratings-main">
                            {
                                isAnimatedRatings ?
                                    ratings?.animated?.map((r, rIndex) => {
                                        return <label key={rIndex} htmlFor={'rating-' + rIndex} className={`${ratingIndex === rIndex ? 'isSelected theme-color theme-primary' : ''}`}>
                                            <div className={`fbs-rating-${r?.score ?? 0} fbs-rating-block app-shadow-default animate__animated`} tabIndex={1}>
                                                <Lottie animationData={r.img} loop={true} className="fbs-rating-animation" />

                                                <input type="radio" name="radio" value={rIndex}
                                                    onClick={() => { setTheRating(r.score); setTheRatingIndex(rIndex); navigateStepWithRating(+1, r.score) }}
                                                    id={'rating-' + rIndex} style={{ display: 'none' }}
                                                />
                                                <span className="fbs-rating-title">{r.name}</span>
                                            </div>
                                        </label>
                                    })
                                    :
                                    ratingContext?.ratingsTemplate?.map((r, rIndex) => {
                                        return <label key={rIndex} htmlFor={'rating-' + rIndex} className={`${ratingIndex === rIndex ? 'isSelected theme-color theme-primary' : ''}`}>
                                            <div className={`fbs-rating-${r?.score ?? 0} fbs-rating-block app-shadow-default animate__animated`} tabIndex={1}>
                                                <img src={r.img} alt={r.name} className="fbs-rating-img" />

                                                <input type="radio" name="radio" value={rIndex}
                                                    onClick={() => { setTheRating(r.score); setTheRatingIndex(rIndex); navigateStepWithRating(+1, r.score) }}
                                                    id={'rating-' + rIndex} style={{ display: 'none' }}
                                                />
                                                <span className="fbs-rating-title">{r.name}</span>
                                            </div>
                                        </label>
                                    })
                            }
                        </div>
                    </div>
                }

                {/* Section (Issue Category) */}
                {viewIndex === PAGE_INDEX.SECTION &&
                    <div className={`animate__animated text-a-center ${isGoingBack ? 'animate__slideInLeft' : 'animate__slideInRight'}`} style={{ width: '100%', padding: "20px" }}>
                        <h3 className="theme-color theme-primary fbs-header-issue-section">{getRatingSectionText()}</h3>
                        <div className="fbs-rating-blocks fbs-items" style={{ color: config_items_color }}>
                            {template?.content?.sections?.map((section, mainSectionIndex) => {
                                return (
                                    <div key={mainSectionIndex} onClick={() => { setSectionIndex(mainSectionIndex); navigateStep(+1, viewIndex, mainSectionIndex) }}
                                        className={`fbs-rating-block fbs-item ${mainSectionIndex === stateSectionIndex ? 'isSelected theme-color theme-primary' : ''}`}
                                    >
                                        <Icon icon={section?.icon} width={config.iconifyIconSize} height={config.iconifyIconSize} className="fbs-rating-img" />
                                        <strong className="fbs-rating-title">{section?.name}</strong>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                }

                {/* Subsections (Sub-issue) */}
                {viewIndex === PAGE_INDEX.SUBSECTION &&
                    <div className={`animate__animated text-a-center ${isGoingBack ? 'animate__slideInLeft' : 'animate__slideInRight'}`} style={{ width: '100%' }}>
                        <h3 className="theme-color theme-primary fbs-header-issue-section-sub">{getRatingSubSectionText()}</h3>
                        <div className="fbs-rating-blocks fbs-items" style={{ color: config_items_color }}>
                            {template?.content?.sections?.map((section, mainSectionIndex) => {
                                return (mainSectionIndex === stateSectionIndex ? <React.Fragment key={mainSectionIndex}>
                                    {section?.sections?.map((subSection, subSectionIndex) => {
                                        return (
                                            <div key={subSectionIndex}
                                                className={`fbs-rating-block fbs-item ${stateSubSectionIndex === subSectionIndex ? 'isSelected theme-color theme-primary' : ''}`}
                                                onClick={() => { setSubSectionIndex(subSectionIndex); navigateStep(+1) }}>
                                                <Icon icon={subSection?.icon} width={config.iconifyIconSize} height={config.iconifyIconSize} className="fbs-rating-img" />
                                                <strong className="fbs-rating-title">{subSection?.name}</strong>
                                            </div>);
                                    })}
                                </React.Fragment>
                                    :
                                    null
                                );
                            })
                            }
                        </div>
                    </div>
                }

                {/* SectionPositive (Delighter Category) */}
                {viewIndex === PAGE_INDEX.SECTION_POSITIVE &&
                    <div className={`animate__animated text-a-center ${isGoingBack ? 'animate__slideInLeft' : 'animate__slideInRight'}`} style={{ width: '100%' }}>
                        <h3 className="theme-color theme-primary fbs-header-issue-section">{getDelighterSectionText()}</h3>
                        <div className="fbs-rating-blocks fbs-items" style={{ color: config_items_color }}>
                            {template?.content?.sectionsPositive?.map((section, mainSectionIndex) => {
                                return (
                                    <div key={mainSectionIndex} onClick={() => { setSectionPosIndex(mainSectionIndex); navigateStep(+1, viewIndex, null, rating, mainSectionIndex) }}
                                        className={`fbs-rating-block fbs-item ${mainSectionIndex === stateSectionPosIndex ? 'isSelected theme-color theme-primary' : ''}`}
                                    >
                                        <Icon icon={section?.icon} width={config.iconifyIconSize} height={config.iconifyIconSize} className="fbs-rating-img" />
                                        <strong className="fbs-rating-title">{section?.name}</strong>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                }

                {/* Subsections (Sub-delighter) */}
                {viewIndex === PAGE_INDEX.SUBSECTION_POSITIVE &&
                    <div className={`animate__animated text-a-center ${isGoingBack ? 'animate__slideInLeft' : 'animate__slideInRight'}`} style={{ width: '100%' }}>
                        <h3 className="theme-color theme-primary fbs-header-issue-section-sub">{getDelighterSubSectionText()}</h3>
                        <div className="fbs-rating-blocks fbs-items" style={{ color: config_items_color }}>
                            {template?.content?.sectionsPositive?.map((section, mainSectionIndex) => {
                                return (mainSectionIndex === stateSectionPosIndex ? <React.Fragment key={mainSectionIndex}>
                                    {section?.sections?.map((subSection, subSectionIndex) => {
                                        return (
                                            <div key={subSectionIndex}
                                                className={`fbs-rating-block fbs-item ${stateSubSectionPosIndex === subSectionIndex ? 'isSelected theme-color theme-primary' : ''}`}
                                                onClick={() => { setSubSectionPosIndex(subSectionIndex); navigateStep(+1) }}>
                                                <Icon icon={subSection?.icon} width={config.iconifyIconSize} height={config.iconifyIconSize} className="fbs-rating-img" />
                                                <strong className="fbs-rating-title">{subSection?.name}</strong>
                                            </div>);
                                    })}
                                </React.Fragment>
                                    :
                                    null
                                );
                            })
                            }
                        </div>
                    </div>
                }

                {/* Feedback Form */}
                {viewIndex === PAGE_INDEX.FEEDBACK &&
                    RenderFeedbackForm()
                }

                {/* Global */}
                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 0", width: "100%" }}>
                    {viewIndex !== 0 &&
                        <div className="cur-pointer theme-color theme-primary button-go-back" style={{ zIndex: 999 }} onClick={() => navigateStep(-1)}>
                            <Icon icon="material-symbols:arrow-back-rounded" width={36} height={36} />
                        </div>
                    }
                    <div>
                        {/* RHS */}
                    </div>
                </div>

                {showEnticer &&
                    <div className={`animate__animated animate__zoomIn cur-pointer bg-cover theme-bg-img ${isMobile ? 'app-container-mobile' : ''}`}
                        style={{ position: 'absolute', top: 0, backgroundImage: `url(${bgImage})`, width: '100%', height: '100vh', textAlign: "center", display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center", overflow: "hidden", zIndex: 101 }}
                        onClick={dismissEnticer}>
                        <RenderHeader />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            {/*<h3 style={{paddingTop: 0 }}>A gift awaits you!</h3>*/}
                            {/*<Lottie animationData={animGiftWaiting} loop={true} style={{width: 400, height: 400, position: "absolute", zIndex: 99, marginTop: -425 }}/>*/}
                            {/*<Lottie animationData={animFbFaces} loop={true} className={`animFace`} />*/}
                            <Lottie animationData={animFloatingFb} loop={true} className={``} />
                            <div style={{ position: 'relative', zIndex: 100, paddingTop: 30, paddingBottom: 0 }}> {/* paddingTop: 150 */}
                                <h3 style={{ margin: 0, paddingBottom: 0 }} className="theme-color theme-primary">Help us serve you better!</h3>
                                <p style={{ color: "#707070" }}>Kindly touch the screen to provide feedback</p>
                            </div>
                            {/*<Lottie animationData={animAwaitingFeedback} loop={true} style={{width: 640, height: 420, marginTop: -80, zIndex:0 }}/>*/}
                        </div>
                    </div>
                }

                {showThankYou &&
                    <div className={`animate__animated animate__zoomIn bg-cover theme-bg-img ${isMobile ? 'app-container-mobile' : ''}`}
                        style={{ position: 'absolute', top: 0, backgroundImage: `url(${bgImage})`, width: '100%', height: '100vh', textAlign: "center", display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center", zIndex: 102 }}
                    >
                        <RenderHeader />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <Lottie animationData={animThankYouStar} loop={true} style={{ width: 230, height: 230 }} />
                            <h3 style={{ paddingTop: 10, marginTop: 0 }}>Thank you for your feedback!</h3>
                            {/*<div style={{position:'relative', zIndex:100, paddingTop: 150}}>
                    <h3 style={{margin:0, padding: 0 }}>We will get in touch</h3>
                    <p>for details regarding your gift</p>
                </div>*/}
                        </div>
                    </div>
                }

                {/* Blocking Loader: Introduced so that on Mobile ratings, the end user does not see default theme before org API response && custom theme setting */}
                {showBlockingLoader &&
                    <div className={`animate__animated bg-cover theme-bg-img ${isMobile ? 'app-container-mobile' : ''}`}
                        style={{ position: 'absolute', top: 0, backgroundImage: `url(${bgImage})`, width: '100%', height: '100vh', textAlign: "center", display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center", zIndex: 102 }}
                    >
                        <RenderHeader />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <h5 style={{ paddingTop: 10, marginTop: 0 }}>Loading ...</h5>
                        </div>
                    </div>
                }
            </div>

            {/* Footer */}
            {isTablet && <RenderFooter />}

        </div>
    );
}

export function FbsSetLocation(params) {
    let navigate = useNavigate();
    let user = getStorage('u4');

    /* Get defaults on firstLoad */
    const [fbsMeta, setFbsMeta] = useState(getFbsMetaLS());

    const goToNextScreen = () => { navigate(paths.tabletView.fbs.feedback, { replace: true }) }
    const goToNextScreenQR = () => { navigate(paths.tabletView.fbs.feedbackQR, { replace: true }) }

    const [locations, setLocations] = useState([]);
    useEffect(() => {
        let r = axios.post('/fbs/locations/list', { org_id: user?.hotel_id }).then(r => {
            setLocations(r.data);
            reset(fbsMeta);
        });
    }, []);
    /* Form */
    const { register, handleSubmit, watch, setValue, getValues, reset, formState: { errors } } = useForm({
        defaultValues: fbsMeta,
    });

    const prepareFbsDataForLS = (formDataPassed) => {
        if (!formDataPassed.location) {
            alert("Please set location");
            return;
        }
        console.log(formDataPassed);
        const formData = { ...formDataPassed };
        formData.id = parseInt(formData.location);
        formData.location = locations.find(l => l.id === formData.id);
        delete formData.id;
        formData.org_id = user?.hotel_id;
        console.log(formData);
        return !!(setStorage('fbsMeta', formData));
    }

    const onSubmit = (formDataPassed) => {
        if (prepareFbsDataForLS(formDataPassed)) goToNextScreen();
    }
    const onSubmitQR = (formDataPassed) => {
        if (prepareFbsDataForLS(formDataPassed)) goToNextScreenQR();
    }

    /* Toggle Fullscreen */
    const [fullscreen, setFullscreen] = useState(false);
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
    };

    return (
        <div className={"animate__animated animate__zoomIn"} style={{ width: '100%', height: '100vh', display: "flex" }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: "center", flexDirection: 'column' }}>
                <small className="opa-050"><span className="fa fa-user-shield"></span> ADMIN CONFIG</small>
                <h3 style={{ margin: 0, marginTop: 10 }} className="theme-color theme-primary">
                    Hello, {user?.fname || user?.name}!
                    <br />
                    You are currently setup to manage <span className="col-green">{user.hotel.name}</span>
                </h3>
                <p style={{ marginTop: 15 }}>If this is wrong, please change the association via the <span>Admin Panel</span>, <br />or contact your system admin</p>
                <form className="fbs-form bg-glass">
                    <div style={{ paddingTop: 10 }}>
                        <label htmlFor="tabletName"><small>Tablet Identifier/Name</small></label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <h3>{fbsMeta?.tabUid}-</h3>
                                <input type="text" {...register("tabUid", { required: true, maxLength: 96 })} className="fbs-form-input" style={{ display: "none" }} />
                            </div>
                            <input type="text" {...register("tabName", { required: true, maxLength: 96 })} className="fbs-form-input" />
                        </div>
                        <ErrorMessage errors={errors} name="tabName" render={({ message }) => <span className="inline-error inline-error-block">{message || "Tab Name is Required"}</span>} />
                    </div>
                    <h4 style={{ paddingTop: 0, fontSize: '1.05rem' }} className="theme-color theme-primary">{getLocationIcon()} Select the location of <span className="col-green">this tablet</span> inside of {user.hotel.name}</h4>
                    <div style={{ position: 'relative', zIndex: 100, paddingTop: 0 }}>
                        <label htmlFor="setLocations"><small>Location</small></label>
                        {/* <select name="setLocations" id="setLocations" className="fbs-form-input"
                            {...register("location", {required: true, maxLength: 96})}
                        >
                            {
                                locations?.map( (l,i) => {
                                    return <option key={l.id} value={l.id}>{l.location}</option>
                                })
                            }
                        </select> */}
                        <Autocomplete
                            options={locations}
                            id="setLocations"
                            onChange={(e, val) => {
                                if (!val) setValue('location', null)
                                else setValue('location', val.id)
                            }}
                            getOptionLabel={(option) => option?.location}
                            renderInput={(params) => (
                                <TextField {...params} className="fbs-form-input" />
                            )}
                        />
                        <ErrorMessage errors={errors} name="location" render={({ message }) => <span className="inline-error inline-error-block">{message || "Please select the location of the tablet"}</span>} />

                    </div>
                    <div className="fbs-form-section-sub" style={{ marginTop: 30 }}>
                        {/* Fullscreen button */}
                        <Button type="submit" modifier="large" onClick={handleSubmit(onSubmitQR)} className="theme-color theme-primary-bg-dark">
                            Set and show QR &raquo;
                        </Button>
                        <Button type="submit" modifier="large--cta" onClick={handleSubmit(onSubmit)} className="theme-color theme-primary-bg">
                            Set and show Ratings &raquo;
                        </Button>
                        <div style={{ marginTop: 30 }}>
                            <Button onClick={toggleFullScreen} modifier="large--quiet">{fullscreen ? "Exit Fullscreen" : "Go Fullscreen"}</Button>
                        </div>
                    </div>
                </form>
                <div style={{ paddingTop: 10 }}>{/*Spacer*/}</div>
            </div>
        </div>
    );
}

export const RenderHeader = (props) => {
    const logoToUse = props.logo ?? getStorage('u4')?.hotel?.logo_img ?? null;
    return (
        <div id="header">
            <div>
                {/* LHS: Empty*/}
            </div>
            <div id="logo">
                {logoToUse ?
                    <img src={logoToUse} alt="" />
                    : <></>}
            </div>
            <div>
                {/* RHS */}
            </div>
        </div>
    );
}

export const RenderFooter = () => {
    const { register, handleSubmit, watch, setValue, getValues, reset, formState: { errors }, control } = useForm();
    const [openModal, setOpenModal] = useState(false);
    const onCloseModal = () => setOpenModal(false);
    const onOpenModal = () => setOpenModal(true);
    const onSubmit = async (formData) => {
        try {
            let res = await axios.post('/auth', {
                u: formData.username,
                p: formData.password
            });
            logout();
        } catch (e) {
            onCloseModal();
            reset();
        }
    }
    let navigate = useNavigate();
    const logout = () => {
        deleteStorage('u4');

        /* Remove FBS Meta, but keep `tabUid` and `tabName` */
        const fbsMeta = getStorage('fbsMeta');
        fbsMeta.org_id = null;
        fbsMeta.location = null;
        setStorage('fbsMeta', fbsMeta);

        navigate(paths.root.login);
    }
    return (
        <div id="footer" className="opa-050">
            <div>
                {/* Left */}
                <span className="fa fa-power-off cur-pointer" onClick={onOpenModal}></span>
            </div>
            <div id="footer-powered-by">
                <span className="footer-text-visible">Powered by {COMPANY_BRAND_NAME}</span>
            </div>
            <div id="footer-location">
                {/* Right */}
                <span id="current-loc" className="footer-text-visible">{getLocationIcon()} {getLocationNameLS()}</span>
            </div>
            <AlertDialog isOpen={openModal} onCancel={onCloseModal} cancelable>
                <div className="alert-dialog-title">Authenticate Logout</div>
                <div className="alert-dialog-content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="username" id="fbs-logout-input" placeholder="Username" {...register("username", { required: true })} />
                        <input type="password" id="fbs-logout-input" placeholder="Password" {...register("password", { required: true })} />
                        <button className="alert-dialog-button">Logout</button>
                    </form>
                    <button onClick={onCloseModal} className="alert-dialog-button">Cancel</button>
                </div>
            </AlertDialog>
        </div>
    )
};

/* COMMON CONST/FNS */
export const getLocationNameLS = () => getStorage('fbsMeta')?.location?.location || "Unknown";
const getLocationIdLS = () => getStorage('fbsMeta')?.location?.id || 0;
export const getLocationIcon = () => <span className="fa fa-map-pin p-r-5"></span>
// uidGen generates a random alpha-numeric UID
const uidGen = (len) => {
    let c = '';
    for (c = ''; c.length < 5;) c += Math.random().toString(36).toUpperCase().substr(2, 1);
    return c;
}
const getFbsMetaLS = () => {
    let fbsMetaLS = getStorage('fbsMeta');
    return {
        tabUid: fbsMetaLS?.tabUid ?? uidGen(5),
        tabName: fbsMetaLS?.tabName ?? "TAB",
        location: fbsMetaLS?.location ?? null,
    }
}

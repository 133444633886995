import React, { useEffect, useState } from "react";
// Standard storage functions
import { getItem, getStorage } from "../utils/storage";
// Checklist-specific storage functions
import {
    getAllSavedChecklists,
    deleteChecklistData,
    migrateToIndexedDB,
    cleanupIndexedDB
} from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { BackButton, List, ListItem, Page, Toolbar, ProgressCircular, Button, AlertDialog, Icon } from "react-onsenui";
import { useDate } from "../useDate";
import { paths } from "../App";
import { makeDate_Std } from "../utils/date-helper";
import { storageCleanReset } from "../utils/storage-clean-reset";

export const ChecklistMySavedDrafts = (props) => {

    const cache_key = 'cache_instance_';

    const [authUser, setAuthUser] = useState(getStorage('u4'));
    const dateRT = useDate();
    const [isLoading, setIsLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);

    /* Navigation */
    let navigate = useNavigate();
    const goBack = () => navigate(paths.checklist.dash, { replace: true });

    const renderListHeader = () => <></>;
    const renderListFooter = () => <></>;

    const [myInstanceDrafts, setMyInstanceDrafts] = useState(null);

    // Initialize database and migrate data on component mount
    useEffect(() => {
        const initialize = async () => {
            setIsLoading(true);
            try {
                // Attempt to migrate data from localStorage to IndexedDB
                await migrateToIndexedDB();

                // Clean up any invalid entries in IndexedDB
                try {
                    const removedCount = await cleanupIndexedDB();
                    if (removedCount > 0) {
                        console.log(`Cleaned up ${removedCount} invalid checklist entries`);
                    }
                } catch (error) {
                    console.warn("Failed to cleanup IndexedDB:", error);
                }

                setInitialized(true);
            } catch (error) {
                console.warn("Failed to initialize IndexedDB:", error);
                // Still set initialized so we can proceed even with localStorage
                setInitialized(true);
            } finally {
                setIsLoading(false);
            }
        };

        initialize();
    }, []);

    // Fetch saved checklists when component is initialized
    useEffect(() => {
        if (initialized) {
            getMyClInstances();
        }
    }, [initialized]);

    // Helper function to extract a valid date from various data structures
    const extractTimestamp = (data) => {
        // Check all possible timestamp locations in order of preference
        const timestamp = data.timestamp ||
            data.data?.timestamp ||
            (data.data?.clInstance?.updatedAt ? new Date(data.data.clInstance.updatedAt) : null) ||
            (data.clInstance?.updatedAt ? new Date(data.clInstance.updatedAt) : null) ||
            new Date();

        // Return a valid Date object
        return timestamp instanceof Date ? timestamp : new Date(timestamp);
    };

    // Helper function to extract a name from various data structures
    const extractName = (data) => {
        return data.data?.clInstance?.name ||
            data?.clInstance?.name ||
            `Checklist ${data.id || data.data?.clInstance?.id || data?.clInstance?.id || 'Unknown'}`;
    };

    // Helper function to extract ID consistently
    const extractId = (data) => {
        let id = data.id || data.data?.clInstance?.id || data?.clInstance?.id || null;
        return id ? String(id) : null;
    };

    // Helper function to check if checklist has images
    const hasImages = (data) => {
        // Check for image references in indexed DB data
        if (data.data && data.data.imageReferences && Array.isArray(data.data.imageReferences)) {
            return data.data.imageReferences.length > 0;
        }
        // Check for images in the imageArray (legacy format or already loaded)
        if (data.data && data.data.imageArray && Array.isArray(data.data.imageArray)) {
            return data.data.imageArray.some(img => img && img.img && img.id);
        }
        // For legacy localStorage data
        if (data.imageArray && Array.isArray(data.imageArray)) {
            return data.imageArray.some(img => img && img.img && img.id);
        }
        return false;
    };

    // Helper function to get image count
    const getImageCount = (data) => {
        if (data.data && data.data.imageReferences && Array.isArray(data.data.imageReferences)) {
            return data.data.imageReferences.length;
        }
        if (data.data && data.data.imageArray && Array.isArray(data.data.imageArray)) {
            return data.data.imageArray.filter(img => img && img.img && img.id).length;
        }
        if (data.imageArray && Array.isArray(data.imageArray)) {
            return data.imageArray.filter(img => img && img.img && img.id).length;
        }
        return 0;
    };

    const getMyClInstances = async () => {
        if (!authUser || !authUser?.id) return;
        setIsLoading(true);

        try {
            // Use getAllSavedChecklists which prioritizes IndexedDB 
            const allChecklists = await getAllSavedChecklists();

            // Filter out any invalid entries
            const validChecklists = allChecklists.filter(item => {
                const id = extractId(item);
                return id !== null && id !== "undefined" && id !== "0";
            });

            // Sort by timestamp, newest first
            validChecklists.sort((a, b) => {
                const dateA = extractTimestamp(a);
                const dateB = extractTimestamp(b);
                return dateB - dateA; // Descending order (newest first)
            });

            setMyInstanceDrafts(validChecklists);
        } catch (error) {
            console.error("Failed to get saved checklists:", error);
            setMyInstanceDrafts([]);
        } finally {
            setIsLoading(false);
        }
    }

    // Delete confirmation
    const [delConfirmIsOpen, setDelConfirmIsOpen] = useState(false);

    const deleteCl = async () => {
        setDelConfirmIsOpen(false);
        setIsLoading(true);

        try {
            if (myInstanceDrafts && myInstanceDrafts.length > 0) {
                // Track deleted checklists to avoid duplicates
                const deletedIds = new Set();

                // Delete each checklist individually using IndexedDB-focused function
                for (const draft of myInstanceDrafts) {
                    // Get the instance ID consistently
                    const instanceId = extractId(draft);

                    if (!instanceId) {
                        console.warn('Skipping draft with no valid ID:', draft);
                        continue;
                    }

                    // Skip if we've already deleted this ID
                    if (deletedIds.has(instanceId)) {
                        console.log(`Skipping already deleted checklist ${instanceId}`);
                        continue;
                    }

                    await deleteChecklistData(instanceId);
                    deletedIds.add(instanceId);
                    console.log(`Deleted checklist ${instanceId}`);
                }
                console.log(`All checklists deleted successfully (${deletedIds.size} total)`);
            }
        } catch (error) {
            console.error("Failed to delete checklists:", error);
        } finally {
            setIsLoading(false);
            // Refresh the list to reflect changes
            getMyClInstances();
        }
    }

    /* Delete all locally stored data */
    const deleteAllLocalData = () => {
        // Close any open modals or popups
        setDelConfirmIsOpen(false);
        setIsLoading(true);

        try {
            /* Clean Reset */
            storageCleanReset();
        } catch (error) {
            console.error("Error during storage reset:", error);
        } finally {
            setIsLoading(false);
            // Refresh the list
            getMyClInstances();
        }
    };

    return (<>
        <Page renderToolbar={() =>
            <Toolbar>
                <div className="left">
                    <BackButton onClick={goBack}>Back</BackButton>
                </div>
                <div className="center">
                    <small>{dateRT}</small>
                </div>
                <div className="right">
                    {/* RHS */}
                </div>
            </Toolbar>
        }>
            {isLoading && (
                <div className="flex flex-both-center flex-dir-col h-100-vh flex-dir-col animate__animated" style={{ position: "absolute", width: "100%", background: "rgba(255,255,255,0.95)", zIndex: 100 }}>
                    <ProgressCircular modifier="material" indeterminate={true} />
                    <h3>Loading...</h3>
                </div>
            )}

            <section style={{ padding: '16px' }}>
                <h2>My Saved Drafts</h2>
                <p>
                    <small>
                        These are checklists that you have saved locally and have not submitted yet.
                        You need an active internet connection to submit them.
                    </small>
                </p>

                {/* When data is loaded but no drafts found */}
                {myInstanceDrafts !== null && (!myInstanceDrafts || myInstanceDrafts.length === 0) && (
                    <div className="text-a-center p-t-b-20 f-w-bold">
                        <p>No saved drafts found</p>
                    </div>
                )}

                {/* When drafts are found */}
                {myInstanceDrafts && myInstanceDrafts.length > 0 && (
                    <List
                        dataSource={myInstanceDrafts}
                        renderHeader={renderListHeader}
                        renderFooter={renderListFooter}
                        renderRow={(data, i) => {
                            // Extract data consistently
                            const instanceId = extractId(data);
                            const name = extractName(data);
                            const timestamp = extractTimestamp(data);
                            const hasImagesFlag = hasImages(data);
                            const imageCount = getImageCount(data);

                            return (
                                <ListItem
                                    key={i}
                                    modifier={(i === (myInstanceDrafts.length - 1)) ? 'longdivider' : null}
                                    onClick={() => {
                                        navigate(paths.checklist.details, {
                                            state: {
                                                data: {
                                                    localDecode: true,
                                                    clInstance_id: instanceId
                                                }
                                            }
                                        });
                                    }}
                                    style={{ borderBottom: '1px solid #cdcdcd' }}
                                >
                                    <div className="p-r-15">
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <strong>{i + 1}.</strong> {name}
                                            </div>
                                        </div>
                                        <small className="d-b f-s-09r p-t-10 col-grey-dark">
                                            <b>ID: {instanceId}</b>
                                            <br />
                                            {data.source && <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                <span style={{ marginRight: '5px' }}>Stored on: {data.source}</span>
                                                {hasImagesFlag && (
                                                    <span style={{
                                                        backgroundColor: '#e8f4ff',
                                                        padding: '3px 6px',
                                                        borderRadius: '8px',
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        fontSize: '0.75rem',
                                                        color: '#0066cc',
                                                        verticalAlign: 'middle',
                                                        lineHeight: '1',
                                                        marginRight: '5px'
                                                    }}>
                                                        <span className="fa fa-camera" style={{ marginRight: '3px', fontSize: '0.7rem' }}></span>
                                                        {imageCount} {imageCount === 1 ? 'image' : 'images'}
                                                    </span>
                                                )}
                                            </span>}
                                            &bull;
                                            Last saved: {makeDate_Std(timestamp.toISOString())}
                                        </small>
                                    </div>
                                </ListItem>
                            );
                        }}
                    />
                )}

                {/* Delete button - show only if there are drafts or we're still loading */}
                {(myInstanceDrafts === null || myInstanceDrafts?.length > 0) && (
                    <div className="m-t-b-20 d-b">
                        <Button
                            onClick={() => setDelConfirmIsOpen(true)}
                            style={{ backgroundColor: "#753020", borderColor: "#753020" }}
                            disabled={isLoading}
                        >
                            Delete all
                        </Button>
                    </div>
                )}

                {/* Delete confirmation dialog */}
                <AlertDialog isOpen={delConfirmIsOpen} onCancel={() => setDelConfirmIsOpen(false)} cancelable>
                    <div className="alert-dialog-title">Delete All Saved Checklists</div>
                    <div className="alert-dialog-content">
                        Are you sure you want to delete all your saved checklist drafts? <br />
                        This action cannot be undone.
                    </div>
                    <div className="alert-dialog-footer">
                        <Button onClick={() => setDelConfirmIsOpen(false)} className="alert-dialog-button">
                            Cancel
                        </Button>
                        <Button onClick={deleteCl} className="alert-dialog-button">
                            Delete
                        </Button>
                    </div>
                </AlertDialog>
            </section>
        </Page>
    </>);
}
